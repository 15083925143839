import { SET_IS_DASHBOARD, UNSET_IS_DASHBOARD, SET_SEMESTER, SET_IS_LOGIN_PAGE, SET_IS_NOT_LOGIN_PAGE } from './type.js'
import { getSemesterNumberApi } from '../services/api/indexApis'
import { checkResponse } from '../helpers/fetchApiHelpers'

export const setDashboard = () => dispatch => {
  dispatch({ type: SET_IS_DASHBOARD })
}

export const unsetDashboard = () => dispatch => {
  dispatch({ type: UNSET_IS_DASHBOARD })
}

const setSemesterNumberAction = semesterNumber => ({
  type: SET_SEMESTER,
  payload: semesterNumber
})

export const setIsLoginPageAction = () => dispatch => {
  dispatch({type: SET_IS_LOGIN_PAGE})
}

export const setIsNotLoginPageAction = () => dispatch => {
  dispatch({type: SET_IS_NOT_LOGIN_PAGE})
}

export const setSemesterNumberActionCreator = () => dispatch => {
  return getSemesterNumberApi()
    .then(checkResponse)
    .then(res => {
      dispatch(setSemesterNumberAction(res.semesterNumber))
    })
    .catch(console.log)
}