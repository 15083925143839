export const checkResponse = async (res) => {
  if (!res.ok) {
    return res.json()
      .catch(() => {
        throw res.statusText
      })
      .then(json => {
        throw json
      })
  } else if (res.status === 201) {
    return null
  } else {
    return res.json()
  }
}