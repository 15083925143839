// import getBrowserLanguage from "get-browser-language";
// import { parse } from "query-string";
import langPacks from "./translation";

export function getLanguage() {
  const lang = localStorage.getItem('user_language') || localStorage.getItem('language')
  return ['en', 'ru', 'ua'].includes(lang) ? lang : 'ua'
}
export function getTranslations(lang, filter) {
  let result = langPacks[lang] || langPacks["en"];
  if (typeof filter === "string") {
    return filter ? result[filter] : result;
  } else if (Array.isArray(filter)) {
    const allData = {};
    filter.forEach(f => {
      Object.assign(allData, result[f]);
    });
    return allData;
  } else {
    console.log("can not get translations");
    return result;
  }
}

export function updateLangTag(newLang) {
  document.querySelector("html[lang]").setAttribute("lang", newLang);
  window.localStorage.setItem("user_language", newLang);
}
