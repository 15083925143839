import { toast } from 'react-toastify'
import React from 'react'

export const showErrMsg = (err) => {
  if (err.message && typeof err.message === 'string') {
    toast.error(err.message)
  }
}

export const showLoading = () => {
  return toast(<img height='40' alt='Loading...' src={require('../assets/kubik.gif')} /> , { closeButton: false, autoClose: false })
}