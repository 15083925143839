import update from 'immutability-helper';
import uuidv1 from 'uuid/v1';
import { toast } from 'react-toastify';

import { 
  LESSON_CONTENT_ADD_FORM_ITEM,
  LESSON_CONTENT_MOVE_FORM_ITEM, 
  LESSON_CONTENT_SET_EDITING_FORM_ITEM_INDEX,
  LESSON_CONTENT_SAVE_FORM_ITEM_CHANGES,
  LESSON_CONTENT_DELETE_FORM_ITEM,
  LESSON_CONTENT_SET_FORM_ITEM_DELTA,
  LESSON_CONTENT_SET_FORM_ITEMS,
  LESSON_CONTENT_SET_IS_SAVED,
  LESSON_CONTENT_SET_LESSON_ID_OF_COPIED,
  LESSON_CONTENT_DELETE_LESSON_ID_OF_COPIED,
  LESSON_CONTENT_BLOCK_COPY,
  LESSON_CONTENT_BLOCK_PASTE
} from '../actions/type';

const initialState = {
  formItems: [],
  editingFormItemIndex: -1,
  isSaved: true,
  lessonIdOfCopiedLessonContent: '',
  isCopied: false
};

const lessonContent = (state = initialState, action) => {
  switch (action.type) {
    case LESSON_CONTENT_SET_FORM_ITEMS:
      return {
        ...state,
        formItems: action.payload
      };

    case LESSON_CONTENT_ADD_FORM_ITEM:
      return update(state, {
        formItems: { $push: [action.payload] }
      });

    case LESSON_CONTENT_MOVE_FORM_ITEM:
      const { from, to } = action.payload;
      const tmpFormItems = [...state.formItems];
      const cutOut = tmpFormItems.splice(from, 1)[0];
      tmpFormItems.splice(to, 0, cutOut);
      return {
        ...state,
        formItems: [...tmpFormItems]
      };

    case LESSON_CONTENT_SET_EDITING_FORM_ITEM_INDEX:
      return {
        ...state,
        editingFormItemIndex: action.payload
      };

    case LESSON_CONTENT_SAVE_FORM_ITEM_CHANGES:
      return update(state, {
        formItems: {
          [state.editingFormItemIndex]: { $set: action.payload }
        } 
      });

    case LESSON_CONTENT_SET_FORM_ITEM_DELTA:
      return update(state, {
        formItems: {
          [state.editingFormItemIndex]: {
            delta: { $set: action.payload }
          }
        }
      });

    case LESSON_CONTENT_DELETE_FORM_ITEM:
      return update(state, {
        formItems: {$splice: [[action.payload, 1]]}
      });

    case LESSON_CONTENT_SET_IS_SAVED:
      return {
        ...state,
        isSaved: action.payload
      };

    case LESSON_CONTENT_SET_LESSON_ID_OF_COPIED:
      return {
        ...state,
        lessonIdOfCopiedLessonContent: action.payload
      };

    case LESSON_CONTENT_DELETE_LESSON_ID_OF_COPIED:
      return {
        ...state,
        lessonIdOfCopiedLessonContent: ''
      };

    case LESSON_CONTENT_BLOCK_COPY:
      const index = action.payload;
      const block = [...state.formItems][index];
      localStorage.setItem('block', JSON.stringify(block));
      toast.success('Copied!', { autoClose: 2500, closeButton: false });
      return {
        ...state,
        isCopied: true,
      };

    case LESSON_CONTENT_BLOCK_PASTE:
      const nextIndex = action.payload + 1;
      const blockStr = localStorage.getItem('block');
      if (!blockStr) {
        toast.warn('Not copied yet!', { autoClose: 2500, closeButton: false });
        return state;
      }
      const id = uuidv1();
      const blockParsed = JSON.parse(blockStr);
      if (blockParsed.id) blockParsed.id = id;
      if (blockParsed.componentName === 'FindCompliance') {
        blockParsed.questionOptions1.forEach(qopt1 => {
          const uuid = `${uuidv1()}${Math.round(Math.random()*1000)}`;
          qopt1.input.attributes.id = uuid;
          qopt1.label.attributes.htmlFor = uuid;
        });
        blockParsed.questionOptions2.forEach(qopt2 => {
          const uuid = `${uuidv1()}${Math.round(Math.random()*1000)}`;
          qopt2.input.attributes.id = uuid;
          qopt2.label.attributes.htmlFor = uuid;
        });
      } else if (Array.isArray(blockParsed.questionOptions)) {
        const isCheckboxes = blockParsed.componentName === 'Checkboxes';
        blockParsed.questionOptions.forEach(qopt => {
          const oldId = qopt.input.attributes.id;
          const uuid = `${uuidv1()}${Math.round(Math.random()*1000)}`;
          if (isCheckboxes) {
            const indexOfCorrectAnswer = blockParsed.correctAnswers.indexOf(oldId);
            if (indexOfCorrectAnswer >= 0) {
              blockParsed.correctAnswers.splice(indexOfCorrectAnswer, 1, uuid);
            }
          } else {
            qopt.input.attributes.name = id;
            if (blockParsed.correctAnswer === oldId) {
              blockParsed.correctAnswer = uuid;
            }
          }
          qopt.input.attributes.id = uuid;
          qopt.label.attributes.htmlFor = uuid;
        });
      }
      const newFormItems = [...state.formItems];
      newFormItems.splice(nextIndex, 0, blockParsed);
      localStorage.removeItem('block');
      toast.success('Pasted!', { autoClose: 2500, closeButton: false });
      return {
        ...state,
        isCopied: false,
        formItems: newFormItems
      };
      
    default:
      return state;
  }
};

export default lessonContent;