import "./Global";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import './styles/Loading.scss'
import App from "./App";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import reducers from "./reducers";
import * as serviceWorker from "./serviceWorker";
import * as pushWorker from './pushWorker'
// import './Global'

// const createStoreWithMiddleware = applyMiddleware(
//   save() // Saving done here
// )(createStore);
// const store = createStoreWithMiddleware(
//   reducers,
//   load() // Loading done here
// );
const hideLoading = () => document.querySelector('.Loading').style.display = 'none'

const middlewares = process.env.NODE_ENV !== 'development' ? applyMiddleware(thunk) : applyMiddleware(thunk, logger) 

const store = createStore(reducers, middlewares);

ReactDOM.render(
  <Provider store={store}>  
    <App hideLoading={hideLoading} />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
pushWorker.register()
