import { 
  SET_USER, DELETE_USER, 
  STUDENT_ADD_RESULT, 
  PUSHWORKER_ADD_SUBSCRIPTION, 
  SET_AVATAR,
  SET_EDITED_PROFILE_DATA, 
  ADD_NOTIFICATION
} from "../actions/type";
import update from 'immutability-helper'
import { getUserlevel } from '../services/Constants'
const initialState = {
  isLoggedIn: false,
  level: 9,
  data: {},
  notifications: [],
  notificationsLoaded: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        isLoggedIn: true,
        level: getUserlevel(action.payload.role),
        data: action.payload 
      }

    case DELETE_USER:
      return {
        ...state,
        isLoggedIn: false,
        level: 9,
        data: {}
      }

    case STUDENT_ADD_RESULT:
      return update(state, {
        data: {
          results: {$push: [action.payload]}
        }
      })

    case PUSHWORKER_ADD_SUBSCRIPTION:
      return update(state, {
        data: {
          subscription: { $set: action.payload }
        }
      })

    case SET_AVATAR:
      return update(state, {
        data: {
          avatar: { $set: action.payload }
        }
      })

    case SET_EDITED_PROFILE_DATA:
      return {
        ...state,
        data: {...state.data, ...action.payload}
      }

    case ADD_NOTIFICATION:
      const notifications = action.payload
      const ids = notifications.map(n => n._id)
      return {
        ...state,
        notifications: notifications,
        notificationsLoaded: true,
        data: {
          ...state.data,
          notifications: ids
        }
      } 

    default:
      return state;
  }
};
