import { SET_VIEW_CONTENT } from "../actions/type";
const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_VIEW_CONTENT:
      return action.payload;

    default:
      return state;
    // break;
  }
};
