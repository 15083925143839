import {
  ADD_NOTIFICATION,
  READ_NOTIFICATION,
  READ_ALL_NOTIFICATION,
  DELETE_NOTIFICATION
} from "../actions/type";
const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:

    case READ_ALL_NOTIFICATION:

    case READ_NOTIFICATION:

    case DELETE_NOTIFICATION:

    default:
      return state;
  }
};
