import { SET_USER, DELETE_USER, SET_AVATAR, SET_EDITED_PROFILE_DATA, SET_ADMINS } from "./type";
import { 
  checkUser, 
  uploadAvatarApi, 
  setNewPasswordApi,
  editProfileApi,
  editUserApi,
  createUserApi,
  getAdminsApi,
  deleteUserApi
} from '../services/api/usersApis'
import { loginApi, setPasswordApi } from '../services/api/indexApis'
import { checkResponse } from '../helpers/fetchApiHelpers'
import { showLoading } from '../helpers/toasts'
import { toast } from 'react-toastify'

let isFetching = false

export const loginAction = (data, successMsg) => dispatch => {
  if (isFetching) return
  isFetching = true
  const toastId = showLoading()
  return loginApi(data)
    .then(checkResponse)
    .then(user => {
      dispatch(setUserInfo(user))
      toast.update(toastId , { type: toast.TYPE.SUCCESS, autoClose: 5000, render: successMsg })
    })
    .catch(err => {
      toast.update(toastId , { type: toast.TYPE.ERROR, autoClose: 5000, render: err.message })
    })
    .finally(() => isFetching = false)
}

let isFetchingAdmins = false

export const getAdminsAction = () => dispatch => {
  if (isFetchingAdmins) return
  isFetchingAdmins = true
  return getAdminsApi()
    .then(checkResponse)
    .then((data) => {
      dispatch({
        type: SET_ADMINS,
        payload: data
      })
    })
    .catch(console.log)
    .finally(() => isFetchingAdmins = false)
}
 
export const setUserInfo = user => dispatch => {
  dispatch ({
    type: SET_USER,
    payload: user
  })
};

export const deleteUserAction = () => dispatch => {
  dispatch({
    type: DELETE_USER
  })
};

export const checkUserAction = () => dispatch => {
  return checkUser()
    .then(res => {
      if (res.ok) {
        return res.json()
      } else {
        dispatch({ type: DELETE_USER })
        throw Error(res.statusText)
      }
    })
    .then(json => {
      dispatch({
        type: SET_USER,
        payload: json
      })
    })
    .catch(console.log)
}

export const setPasswordAction = (data, token, successMsg, cb) => dispatch => {
  if (isFetching) return
  isFetching = true
  const toastId = showLoading()
  return setPasswordApi(data, token)
    .then(checkResponse)
    .then(({user, resetMode}) => {
      if (cb) cb(resetMode)
      toast.update(toastId, { type: toast.TYPE.SUCCESS, autoClose: 5000, render: successMsg })
      if (resetMode) {
        dispatch({
          type: SET_USER,
          payload: user
        })
      }
    })
    .catch(err => {
      toast.update(toastId , { type: toast.TYPE.ERROR, autoClose: 5000, render: err.message })
    })
    .finally(() => isFetching = false)
}

export const uploadAvatarAction = (formData, successMsg) => dispatch => {
  if (isFetching) return
  isFetching = true
  const toastId = showLoading()
  return uploadAvatarApi(formData)
    .then(checkResponse)
    .then(({avatarUrl}) => {
      dispatch({
        type: SET_AVATAR,
        payload: avatarUrl
      })
      toast.update(toastId, { type: toast.TYPE.SUCCESS, autoClose: 5000, render: successMsg })
    })
    .catch(err => {
      toast.update(toastId , { type: toast.TYPE.ERROR, autoClose: 5000, render: err.message })
    })
    .finally(() => isFetching = false)
}

export const setNewPasswordAction = (data, successMsg) => {
  if (isFetching) return
  isFetching = true
  const toastId = showLoading()
  return setNewPasswordApi(data)
    .then(checkResponse)
    .then(() => {
      toast.update(toastId, { type: toast.TYPE.SUCCESS, autoClose: 5000, render: successMsg })
    })
    .catch(err => {
      toast.update(toastId , { type: toast.TYPE.ERROR, autoClose: 5000, render: err.message })
    })
    .finally(() => isFetching = false)
}

export const createUserAction = (data, successMsg) => dispatch => {
  if (isFetching) return
  isFetching = true
  const toastId = showLoading()
  return createUserApi(data)
    .then(checkResponse)
    .then(() => {
      toast.update(toastId, { type: toast.TYPE.SUCCESS, autoClose: 5000, render: successMsg })
    })
    .catch(err => {
      toast.update(toastId , { type: toast.TYPE.ERROR, autoClose: 5000, render: err.message })
    })
    .finally(() => isFetching = false)
}

export const editUserAction = (data, id, successMsg) => dispatch => {
  if (isFetching) return
  isFetching = true
  const toastId = showLoading()
  return editUserApi(data, id)
    .then(checkResponse)
    .then(() => {
      toast.update(toastId, { type: toast.TYPE.SUCCESS, autoClose: 5000, render: successMsg })
    })
    .catch(err => {
      toast.update(toastId , { type: toast.TYPE.ERROR, autoClose: 5000, render: err.message })
    })
    .finally(() => isFetching = false)
}

export const deleteUser = (userId, cb, successMsg) => dispatch => {
  if (isFetching) return;
  isFetching = true;
  const toastId = showLoading();
  return deleteUserApi(userId)
    .then(checkResponse)
    .then(() => {
      toast.update(toastId, { type: toast.TYPE.SUCCESS, autoClose: 5000, render: successMsg });
      if (cb) cb();
    })
    .catch(err => {
      toast.update(toastId , { type: toast.TYPE.ERROR, autoClose: 5000, render: err.message });
    })
    .finally(() => {
      isFetching = false;
    });
};

export const editProfileAction = (data, successMsg) => dispatch => {
  if (isFetching) return
  isFetching = true
  const toastId = showLoading()
  return editProfileApi(data)
    .then(checkResponse)
    .then(({user}) => {
      toast.update(toastId, { type: toast.TYPE.SUCCESS, autoClose: 5000, render: successMsg })
      dispatch({
        type: SET_EDITED_PROFILE_DATA,
        payload: user
      })
    })
    .catch(err => {
      toast.update(toastId , { type: toast.TYPE.ERROR, autoClose: 5000, render: err.message })
    })
    .finally(() => isFetching = false)
}