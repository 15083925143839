import { SET_TEACHERS } from "../actions/type";

const initialState = [{
  _id: '',
  firstName: '',
  lastName: '',
  fullName: '',
}];

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TEACHERS:
      return action.payload;

    default:
      return state;
  }
};
