import FlagEn from "../assets/icons/flag(en).svg";
import FlagRu from "../assets/icons/flag(ru).svg";
import FlagUa from "../assets/icons/flag(ua).svg";
import getSchoolClassName from './getSchoolClassName'
// import { ic_trending_down } from "react-icons-kit/md";
// import dashboardIcon from '../assets/icon/dashboard.svg';
// import usermanageIcon from '../assets/icon/user_manage.svg';
// import content_manageIcon from '../assets/icon/content_manage.svg';
// import class_manageIcon from '../assets/icon/class_manage.svg';
// import sutdyManageIcon from '../assets/icon/plan_manage.svg';
// import classContentIcon from '../assets/icon/plan_edit.svg';
export const baseUrl =
  process.env.NODE_ENV !== "production" ? process.env.REACT_APP_SERVER_URL : "";
export const Regex = {
  // email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{2,21}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,21}[a-zA-Z0-9])?)*$/
  // remove only unescape email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]{2,21}\.)+[a-zA-Z]{2,21}))$/,
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]{1,21}\.)+[a-zA-Z]{2,21}))$/,
  phone: /^\+380\d{9}$/,
  // username: /^[[:alnum:]]+(?:[-_ ]?[[:alnum:]]+)*$/
  username: /^(?=.{3,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?![_.]).$/
};
export const getUserByIdfromList = (list = [], id = "") => {
  return list.find(el => {
    return el._id === id;
  });
};

export const colors = {
  primary: "#b8d23d",
  secondary: "#160c69",
  info: "#c5c0f0",
  black: '#464646'
};
export const fonts = {
  mainfont: '"Arsenal", sans-sarif',
  subfont: '"Tinos", sans-sarif'
};
export const languageArray = [
  {
    code: "en",
    img: FlagEn, //"http://via.placeholder.com/30x15",
    name: "English"
  },
  {
    code: "ru",
    img: FlagRu, //"http://via.placeholder.com/30x15",
    // name: "Russian"
    name: "Русский"
  },
  {
    code: "ua",
    img: FlagUa, //"http://via.placeholder.com/30x15",
    // name: "Ukrainian"
    name: "Українська"
  }
];

export const selectStyle = {
  valueContainer: styles => ({
    ...styles,
    backgroundColor: "transparant",
    padding: 0
  }),
  control: (styles, { isFocused, isSelected }) => ({
    ...styles,
    minHeight: "50px",
    border: `2px solid ${colors.primary}`,
    borderRadius: "10px",
    paddingLeft: "0.8889rem ",
    boxShadow: isFocused ? 0 : 0,
    "&:hover": {
      border: `2px solid ${colors.primary}`
    }
  }),
  dropdownIndicator: styles => ({
    ...styles,
    cursor: "pointer"
  }),
  input: styles => ({
    ...styles,
    fontSize: "0.7778rem",
    "&:placeholder": {
      color: "rgba(65, 51, 183, 0.5)"
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontFamily: fonts.mainfont,
      fontSize: "0.7778rem",
      color: colors.primary,
      backgroundColor: "transparent",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(197, 192, 240, 0.25)"
      }
    };
  },
  singleValue: styles => ({
    ...styles,
    fontFamily: "Arsenal",
    fontSize: "1rem",
    color: colors.primary,
    fontWeight: "bold"
  }),
  menu: styles => ({
    ...styles,
    borderRadius: "10px",
    border: `2px solid ${colors.primary}`,
    marginTop: 0,
    zIndex: 100
  }),
  multiValueLabel: styles => ({
    ...styles,
    fontFamily: "Arsenal",
    fontSize: "0.7778rem",
    color: colors.primary
  }),
  placeholder: styles => ({
    ...styles,
    fontFamily: fonts.mainfont,
    fontSize: "0.7778rem",
    color: colors.primary
  }),
  multiValue: styles => ({
    ...styles,
    border: `2px solid ${colors.primary}`,
    borderRadius: "10px",
    backgroundColor: "rgba(197, 192, 240, 0.25)",
    fontFamily: fonts.mainfont,
    fontSize: "0.7778rem",
    color: colors.primary
  }),
  multiValueRemove: styles => ({
    ...styles,
    cursor: "pointer",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: colors.primary
    }
  })
};

export const selectStyleInput = {
  valueContainer: styles => ({
    ...styles,
    backgroundColor: "transparant",
    padding: 0
  }),
  control: (styles, { isFocused, isSelected }) => ({
    ...styles,
    minHeight: "50px",
    border: `2px solid ${colors.primary}`,
    borderRadius: "10px",
    paddingLeft: "0.8889rem ",
    boxShadow: isFocused ? 0 : 0,
    "&:hover": {
      border: `2px solid ${colors.primary}`
    }
  }),
  input: styles => ({
    ...styles,
    fontSize: "0.7778rem",
    "&:placeholder": {
      color: "rgba(65, 51, 183, 0.5)"
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontFamily: fonts.mainfont,
      fontSize: "0.7778rem",
      color: colors.primary,
      backgroundColor: "transparent",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(197, 192, 240, 0.25)"
      }
    };
  },
  singleValue: styles => ({
    ...styles,
    fontFamily: "Arsenal",
    fontSize: "1rem",
    color: colors.primary,
    fontWeight: "bold"
  }),
  menu: styles => ({
    ...styles,
    borderRadius: "10px",
    border: `2px solid ${colors.primary}`,
    marginTop: 0,
    zIndex: 100
  }),
  multiValueLabel: styles => ({
    ...styles,
    fontFamily: "Arsenal",
    fontSize: "0.7778rem",
    color: colors.primary
  }),
  multiValue: styles => ({
    ...styles,
    border: `2px solid ${colors.primary}`,
    borderRadius: "7px",
    backgroundColor: "rgba(197, 192, 240, 0.25)",
    fontFamily: fonts.mainfont,
    fontSize: "0.7778rem",
    color: colors.primary
  }),
  placeholder: styles => ({
    ...styles,
    fontFamily: fonts.mainfont,
    fontSize: "0.7778rem",
    color: '#808080'
  }),
  dropdownIndicator: styles => ({
    ...styles,
    display: "none"
  }),
  multiValueRemove: styles => ({
    ...styles,
    cursor: "pointer",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: colors.primary
    }
  })
};

export const selectStyleInputNew = {
  valueContainer: styles => ({
    ...styles,
    backgroundColor: "transparant",
    padding: 0
  }),
  control: (styles, { isFocused, isSelected }) => ({
    ...styles,
    border: `5px solid #b8d23d`,
    borderRadius: "10px",
    paddingLeft: "0.8889rem ",
    boxShadow: isFocused ? 0 : 0,
    "&:hover": {
      // border: `2px solid `
    }
  }),
  input: styles => ({
    ...styles,
    "&:placeholder": {
      // color: "rgba(65, 51, 183, 0.5)"
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontFamily: fonts.mainfont,
      color: "#000",
      backgroundColor: "transparent",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(197, 192, 240, 0.25)"
      }
    };
  },
  singleValue: styles => ({
    ...styles,
    fontFamily: fonts.mainfont,
    fontSize: "16px",
    color: "#000"
  }),
  menu: styles => ({
    ...styles,
    borderRadius: "10px",
    color: "#000",
    border: `5px solid #b8d23d`,
    marginTop: 0,
    zIndex: 100
  }),
  placeholder: styles => ({
    ...styles,
    fontFamily: fonts.mainfont,
    fontSize: "16px",
    color: "#000"
  }),
  dropdownIndicator: styles => ({
    ...styles,
    display: "none"
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: "none"
  })
};

export const selectStyleInputGrayBorder = {
  valueContainer: styles => ({
    ...styles,
    backgroundColor: "transparant",
    padding: 0
  }),
  control: (styles, { isFocused, isSelected }) => ({
    ...styles,
    border: `2px solid #B7B7B7;`,
    borderRadius: "10px",
    paddingLeft: "0.8889rem ",
    boxShadow: isFocused ? 0 : 0,
    minHeight: 0,
    "&:hover": {
      // border: `2px solid `
    }
  }),
  input: styles => ({
    ...styles,
    "&:placeholder": {
      // color: "rgba(65, 51, 183, 0.5)"
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontFamily: fonts.mainfont,
      color: "#000",
      backgroundColor: "transparent",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(197, 192, 240, 0.25)"
      }
    };
  },
  singleValue: styles => ({
    ...styles,
    fontFamily: fonts.mainfont,
    fontSize: "16px",
    color: "#000"
  }),
  menu: styles => ({
    ...styles,
    borderRadius: "10px",
    color: "#000",
    border: `5px solid #b8d23d`,
    marginTop: 0,
    zIndex: 100
  }),
  placeholder: styles => ({
    ...styles,
    fontFamily: fonts.mainfont,
    fontSize: "16px",
    color: "#000"
  }),
  dropdownIndicator: styles => ({
    ...styles,
    display: "none"
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: "none"
  })
};

export const selectStyleInputWhiteBorder = {
  container: styles => ({
    ...styles,
    backgroundColor: "transparant",
    padding: 0
  }),
  control: (styles, { isFocused, isSelected }) => ({
    ...styles,
    overflow: 'hidden',
    height: "30px",
    border: `2px solid #fff`,
    background: colors.primary,
    borderRadius: "30px",
    paddingLeft: "0.8889rem ",
    boxShadow: isFocused ? 0 : 0,
    "&:hover": {
      border: `2px solid #fff`
    }
  }),
  input: styles => ({
    ...styles,
    fontSize: "0.7778rem",
    "&:placeholder": {
      color: "rgba(65, 51, 183, 0.5)"
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      display: 'flex',
      alignItems: 'center',
      fontFamily: fonts.mainfont,
      fontSize: "16px",
      color: '#fff',
      backgroundColor: colors.primary,
      cursor: "pointer",
      borderRadius: '15px',
      height: '38px',
      "&:hover": {
        backgroundColor: "rgba(197, 192, 240, 0.25)"
      }
    };
  },
  singleValue: styles => ({
    ...styles,
    fontFamily: "Arsenal",
    fontSize: "16px",
    color: '#fff',
  }),
  menu: styles => ({
    ...styles,
    borderRadius: "20px",
    border: `2px solid #fff`,
    background: colors.primary,
    marginTop: 0,
    zIndex: 100,
    padding: '5px 0',
    // height: '38px'
  }),
  multiValueLabel: styles => ({
    ...styles,
    fontFamily: "Arsenal",
    fontSize: "0.7778rem",
    color: colors.primary
  }),
  multiValue: styles => ({
    ...styles,
    border: `2px solid ${colors.primary}`,
    borderRadius: "7px",
    backgroundColor: "rgba(197, 192, 240, 0.25)",
    fontFamily: fonts.mainfont,
    fontSize: "0.7778rem",
    color: colors.primary
  }),
  placeholder: styles => ({
    ...styles,
    fontFamily: fonts.mainfont,
    fontSize: "16px",
    color: '#fff'
  }),
  multiValueRemove: styles => ({
    ...styles,
    cursor: "pointer",
    backgroundColor: colors.primary,
    "&:hover": {
      backgroundColor: "transparent",
      color: colors.primary
    }
  }),
  dropdownIndicator: styles => ({
    ...styles,
    position: 'absolute',
    right: '-4px',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #fff',
    borderRight: 'none',
    height: '38px',
    borderRadius: '30px',
    width: '72px',
    color: '#fff',
    "&:hover": {
      color: '#fff'
    }
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none'
  })
};

export const selectStyleInputBlackBorder = {
  container: styles => ({
    ...styles,
    backgroundColor: "transparant",
    padding: 0
  }),
  control: (styles, { isFocused, isSelected }) => ({
    ...styles,
    overflow: 'hidden',
    height: "30px",
    border: `3px solid ${colors.black}`,
    background: '#fff',
    borderRadius: "30px",
    paddingLeft: "4px",
    boxShadow: isFocused ? 0 : 0,
    alignItems: 'center',
    "&:hover": {
      border: `3px solid ${colors.black}`
    }
  }),
  input: styles => ({
    ...styles,
    fontSize: "0.7778rem",
    "&:placeholder": {
      color: "rgba(65, 51, 183, 0.5)"
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      display: 'flex',
      alignItems: 'center',
      fontFamily: fonts.mainfont,
      fontSize: "16px",
      color: '#000',
      backgroundColor: '#fff',
      cursor: "pointer",
      borderRadius: '15px',
      height: '38px',
      "&:hover": {
        backgroundColor: "rgba(197, 192, 240, 0.25)"
      }
    };
  },
  singleValue: styles => ({
    ...styles,
    fontFamily: "Arsenal",
    fontSize: "16px",
    color: colors.black,
  }),
  menu: styles => ({
    ...styles,
    borderRadius: "20px",
    border: `3px solid ${colors.black}`,
    background: '#fff',
    marginTop: 0,
    zIndex: 100,
    padding: '5px 0',
    // height: '38px'
  }),
  multiValueLabel: styles => ({
    ...styles,
    fontFamily: "Arsenal",
    fontSize: "0.7778rem",
    color: '#000'
  }),
  multiValue: styles => ({
    ...styles,
    border: `1px solid ${colors.primary}`,
    borderRadius: "7px",
    backgroundColor: '#fff',
    fontFamily: fonts.mainfont,
    fontSize: "0.7778rem",
    margin: 0,
    color: '#000'
  }),
  placeholder: styles => ({
    ...styles,
    fontFamily: fonts.mainfont,
    fontSize: "16px",
    color: '#464646'
  }),
  multiValueRemove: styles => ({
    ...styles,
    cursor: "pointer",
    backgroundColor: colors.primary,
    "&:hover": {
      backgroundColor: "transparent",
      color: colors.primary
    }
  }),
  dropdownIndicator: styles => ({
    ...styles,
    position: 'absolute',
    right: '-2px',
    justifyContent: 'center',
    alignItems: 'center',
    border: `3px solid ${colors.black}`,
    borderRight: 'none',
    height: '38px',
    borderRadius: '30px',
    width: '72px',
    color: colors.black,
    "&:hover": {
      color: colors.black
    }
  }),
  clearIndicator: styles => ({
    ...styles,
    display: 'none'
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none'
  })
};

export const selectStyleInputInTable = {
  container: (styles) => ({
    ...styles,
  }),
  control: (styles, {isFocused}) => ({
    ...styles,
    border: isFocused ? 0 : 0,
    boxShadow: isFocused ? 0 : 0,
    "&:hover": {
      border: 0
    }
  }),
  input: styles => ({
    ...styles,
    border: 'none',
    '&:focus': {
      border: 'none'
    }
  }),
  singleValue: styles => ({
    ...styles,
    fontFamily: "Arsenal",
    fontSize: "16px",
  }),
  menu: styles => ({
    ...styles,
  }),
  placeholder: styles => ({
    ...styles,
    fontFamily: fonts.mainfont,
    fontSize: "16px",
  }),
  indicatorsContainer: (styles, {isSelected}) => ({
    ...styles,
    display: isSelected ? 'none' : 'block'
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none'
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    display: state.isSelected ? 'none' : 'block'
  })
};


export const disciplinesOptions = [
  { value: "Math", label: "Math" },
  { value: "Art_graphics", label: "Art graphics" },
  { value: "History_of_Ukraine", label: "History of Ukraine" },
  { value: "Astronomy", label: "Astronomy" }
];

export const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, false] }, { font: ["Arsenal", "Tinos"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link", "image", "video"],
    ["clean"]
  ]
};

export const statusOptions = [
  { value: "public", label: "Public" },
  { value: "hidden", label: "Hidden" },
  { value: "archived", label: "Archived" }
];

export const tagOptions = [
  { value: "beginner", label: "Beginner" },
  { value: "advance", label: "Advance" },
  { value: "expert", label: "Expert" }
];

export const languageOptions = [
  { value: "en", label: "En" },
  { value: "ru", label: "Ru" },
  { value: "ua", label: "Ua" }
];

export const teacherOptions = (teachers = []) => {
  return teachers.map(t => {
    return {
      value: t._id,
      label: `${t.firstName} ${t.lastName}`
    };
  });
};

export const schoolClassOptions = (schoolClasses = []) => {
  return schoolClasses.map(sc => {
    return {
      value: sc._id,
      label: getSchoolClassName(sc)
    };
  });
};

export const getStudentOptions = (students) => (
  students.map(s => ({ value: s._id, label: `${s.fullName} ${s.parentName}` }))
)

export const userRoleOptions = (lang) => ([
  {value: 'teacher', label: lang.teacher},
  {value: 'parents', label: lang.parents},
  {value: 'student', label: lang.student},
  {value: 'admin', label: lang.admin},
])

export const userRoleMultipleOptions = (lang) => ([
  {value: 'teachers', label: lang.teachers},
  {value: 'parents', label: lang.parents},
  {value: 'students', label: lang.students},
  {value: 'admins', label: lang.admins}
])

export const getSubjectOptions = lang => ([
  { value: "Math", label: lang && lang.Math ? lang.Math : 'Math' },
  { value: "Art_graphics", label: lang && lang.Art_graphics ? lang.Art_graphics : 'Art_graphics' },
  { value: "History_of_Ukraine", label: lang && lang.History_of_Ukraine ? lang.History_of_Ukraine : 'History_of_Ukraine' },
  { value: "Astronomy", label: lang && lang.Astronomy ? lang.Astronomy : 'Astronomy' }
])

export const levels = {
  superadmin: 0,
  admin: 1,
  teacher: 2,
  student: 3,
  parent: 4,
}

export const schoolClassTypes = {
  pure: 'pure',
  externat: 'externat',
  alternative: 'alternative'
}

export const getUserlevel = (role) => levels[role]