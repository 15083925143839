import { PUSHWORKER_ADD_SUBSCRIPTION, ADD_NOTIFICATION } from './type'
import { postSubscriptionApi, getNotificationsByUserApi, deleteNotificationsApi } from '../services/api/notificationApis'
import { checkResponse } from '../helpers/fetchApiHelpers'

const postSubscriptionAction = subscription => ({
  type: PUSHWORKER_ADD_SUBSCRIPTION,
  payload: subscription
})

export const postSubscriptionActionCreator = subscription => dispatch => {
  return postSubscriptionApi(subscription)
    .then(checkResponse)
    .then(res => {
      dispatch(postSubscriptionAction(res))
    })
    .catch(console.log)
}

export const getNotificationsByUserAction = () => dispatch => {
  return getNotificationsByUserApi()
    .then(checkResponse)
    .then(notifications => {
      dispatch({
        type: ADD_NOTIFICATION,
        payload: notifications
      })
    })
}

export const deleteNotificationsAction = data => dispatch => {
  return deleteNotificationsApi(data)
    .then(checkResponse)
    .then(() => {
      getNotificationsByUserAction()(dispatch)
    })
}