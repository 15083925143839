import { 
  SCHOOLCLASS_ADD, 
  SCHOOLCLASS_UPDATE,
  SCHOOLCLASS_SET_ALL,
} from '../actions/type'
import update from 'immutability-helper'

const initialState = {
  classes: [
    {
      students: []
    }
  ],
  // isFetching: false
  isLoaded: false
}

const schoolClass = (state = initialState, action) => {
  switch (action.type) {
    case SCHOOLCLASS_ADD:
      return update(state, {
        classes: {$push: [action.payload]}
      })

    case SCHOOLCLASS_UPDATE:
      const classes = [...state.classes]
      const updatedClasses = classes.map(c => {
        if (c._id === action.payload._id) {
          return action.payload
        } else {
          return c
        }
      })
      return {
        ...state,
        classes: updatedClasses
      }

    case SCHOOLCLASS_SET_ALL:
      return {
        ...state,
        classes: action.payload,
        isLoaded: true
      }

    // case SCHOOLCLASS_ADD_STUDENTS_START:
    //   return {
    //     ...state,
    //     isFetching: true
    //   }

    // case SCHOOLCLASS_ADD_STUDENTS_END:
    //   return {
    //     ...state,
    //     isFetching: false
    //   }
  
    default:
      return state
  }
}

export default schoolClass