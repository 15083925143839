import { SET_SUBJECTNAMES } from '../actions/type'

const initialState = {
  data: [],
  // isFetched: false,
  // isFetching: false,
  isLoaded: false
}

const subjectNames = (state = initialState, action) => {
  switch (action.type) {

    case SET_SUBJECTNAMES:
      return {
        ...state,
        data: action.payload,
        isLoaded: true
      }

    // case SUBJECTNAME_ADD_START:
    //   return {
    //     ...state,
    //     isFetching: true
    //   }

    // case SUBJECTNAME_ADD_END:
    //     return {
    //       ...state,
    //       isFetching: false
    //     }

    default:
      return state
  }
}

export default subjectNames