import {
  SET_LESSONS,
  UPDATE_LESSON_BY_ID,
  CREATE_LESSON,
  LESSON_CONFIRM,
  LESSON_UPDATE,
  LESSON_ADD
} from "../actions/type";

const initialState = [
  {
    _id: '',
    date: '',
    classNumber: '',
    semesterNumber: '',
    lessonNumber: '',
    themeName: '',
    hasTest: '',
    hasControlWork: '',
    isRequiredToDo: '',
    isExternat: '',
    isControlWork: ''
  }
];

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LESSONS:
      return action.payload;

    case UPDATE_LESSON_BY_ID:
      const lessonId = action.payload._id;
      const newState = [...state];
      for (let i = 0; i < newState.length; i++) {
        if (newState[i]._id === lessonId) {
          newState[i] = action.payload;
          return newState;
        }
      }
      return state;

    case CREATE_LESSON:
      // newState = [...state]; 
      // newState.push(action.payload);
      // return newState
      return [...state, action.payload];

    case LESSON_CONFIRM:
      const lessonsToSetConfirmed = state.map(ls => {
        if (ls._id === action.payload) {
          ls.isConfirmed = true
        } 
        return ls
      })

      return lessonsToSetConfirmed 

    case LESSON_UPDATE:
      const lessonsUpdated = [...state].map(ls => {
        if (ls._id === action.payload._id) return action.payload
        return ls
      })
      return lessonsUpdated

    case LESSON_ADD:
      const lessonsAdded = [...state]
      lessonsAdded.push(action.payload)
      return lessonsAdded

    default:
      return state;
  }
};
