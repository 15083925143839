import { baseUrl } from "../Constants";
import setCredentialsHeader from '../setCredentialsHeader'

export const postSubscriptionApi = (data) => {
  return fetch(`${baseUrl}/notification/subscription`, {
    method: "POST",
    credentials: setCredentialsHeader(),
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
};

export const postNotificationApi = (data) => {
  return fetch(`${baseUrl}/notification`, {
    method: "POST",
    credentials: setCredentialsHeader(),
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
};

export const notifyDevApi = (data) => {
  return fetch(`${baseUrl}/notification/notifyDev`, {
    method: "POST",
    credentials: setCredentialsHeader(),
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
};

export const getNotificationsByUserApi = () => {
  return fetch(`${baseUrl}/notification`, {
    method: "GET",
    credentials: setCredentialsHeader(),
  });
};

export const deleteNotificationsApi = (data) => {
  return fetch(`${baseUrl}/notification`, {
    method: "DELETE",
    credentials: setCredentialsHeader(),
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
};