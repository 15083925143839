import { baseUrl } from '../Constants'
import setCredentialsHeader from '../setCredentialsHeader'

export const setPreferedLangApi = lang => {
  return fetch(`${baseUrl}/language`, {
    method: 'POST',
    credentials: setCredentialsHeader(),
    headers: {
      'Content-Language': lang
    }
  })
}

export const registerApi = (data) => {
  return fetch(`${baseUrl}/register`, {
    method: 'POST',
    headers: {
      'Accept': "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
} 

export const setPasswordApi = (data, token) => {
  return fetch(`${baseUrl}/setpassword/${token}`, {
    method: 'POST',
    credentials: setCredentialsHeader(),
    headers: {
      'Accept': "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
} 

export const resetPasswordApi = (data) => {
  return fetch(`${baseUrl}/resetPassword`, {
    method: 'POST',
    headers: {
      'Accept': "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
} 

export const loginApi = data => {
  return fetch(`${baseUrl}/login`, {
    method: "POST",
    credentials: setCredentialsHeader(),
    headers: {
      'Accept': "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
};

export function checkUsernameExists(username) {
  return fetch(`${baseUrl}/checkUsername`, {
    method: 'POST',
    headers: {
      'Accept': "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({username})
  })
}

export function checkEmailExists(email) {
  return fetch(`${baseUrl}/checkEmailExists`, {
    method: 'POST',
    headers: {
      'Accept': "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({email})
  })
}

export const getSemesterNumberApi = () => {
  return fetch(`${baseUrl}/getSemesterNumber`)
}