import {
  TOGGLE_CHAT,
  OPEN_CHAT,
  CLOSE_CHAT,
  OPEN_USER_CHAT
} from "../actions/type";

const initialState = {
  isOpen: false,
  selectedUser: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CHAT:
      return {
        ...state,
        isOpen: !state.isOpen
      };

    case OPEN_CHAT:
      return {
        ...state,
        isOpen: true
      };

    case CLOSE_CHAT:
      return {
        ...state,
        isOpen: false
      };

    case OPEN_USER_CHAT:
      return {
        ...state,
        isOpen: true,
        selectedUser: action.payload
      };

    default:
      return state;
  }
};
