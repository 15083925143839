const publicKey = 'BDHjG9igo1jqL8ymbYOO85alzGjpEN7LwOedRfX-15dcwn2AtEwcwVwF77xFeR--kLOtnUSHZMTsItNL6vSwK2A';

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');
 
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
 
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function register() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/push-worker.js`, {scope: '/dashboard'})
        .then(console.log('push worker registered'))
        .catch(err => console.log(err))
    })
  }
}

export async function subscribe() {
  try {
    const registration = await navigator.serviceWorker.getRegistration()
    const subscription = await registration.pushManager.getSubscription()
    const isSubscribed = !(subscription === null);
    if (!isSubscribed) {
      return await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicKey)
      })
    }
    return null;
  } catch(e) {
    console.log(e)
    return null
  }
}