import { baseUrl } from '../Constants'
import setCredentialsHeader from '../setCredentialsHeader'

export const uploadAvatarApi = formData => {
  return fetch(`${baseUrl}/users/avatar`, {
    method: 'POST',
    credentials: setCredentialsHeader(),
    body: formData 
  })
}

export const getAdminsApi = () => {
  return fetch(`${baseUrl}/users/admins`, {
    method: "GET",
    credentials: setCredentialsHeader(),    
  })
}

export const getpassApi = (userId) => {
  return fetch(`${baseUrl}/users/pass?userId=${userId}`, {
    method: "GET",
    credentials: setCredentialsHeader(),    
  })
}

export const deleteUserApi = (userId) => {
  return fetch(`${baseUrl}/users/${userId}`, {
    method: "DELETE",
    credentials: setCredentialsHeader(),    
  });
};

export const fetchUserApi = userId => {
  return fetch(`${baseUrl}/users/${userId}`, {
    method: "GET",
    credentials: setCredentialsHeader(),    
  })
}

export const setNewPasswordApi = data => {
  return fetch(`${baseUrl}/users/setNewPassword`, {
    method: 'POST',
    credentials: setCredentialsHeader(),
    headers: {
      'Accept': "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
} 

export const createUserApi = (data) => {
  return fetch(`${baseUrl}/users/createUser`, {
    method: 'POST',
    credentials: setCredentialsHeader(),
    headers: {
      'Accept': "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
} 

export const editProfileApi = data => {
  return fetch(`${baseUrl}/users/editProfile`, {
    method: 'PUT',
    credentials: setCredentialsHeader(),
    headers: {
      'Accept': "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
} 

export const editUserApi = (data, userId) => {
  return fetch(`${baseUrl}/users/editUser/${userId}`, {
    method: 'PUT',
    credentials: setCredentialsHeader(),
    headers: {
      'Accept': "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
} 

export const approveTeacherApi = bodyString => {
  return fetch(`${baseUrl}/users/permissions/approve`, {
    method: "POST",
    credentials: setCredentialsHeader(),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(bodyString)
  });
};

export const changePlan = (plan = "Free plan", bodyString) => {
  return fetch(`${baseUrl}/users/students/changeplan`, {
    method: "POST",
    credentials: setCredentialsHeader(),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      id: bodyString,
      plan: plan
    })
  });
};

export const changeRole = (role = "teacher", bodyString) => {
  return fetch(`${baseUrl}/users/edit/role/${role}`, {
    method: "POST",
    credentials: setCredentialsHeader(),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      id: bodyString
    })
  });
};

export const createStudentApi = bodyString => {
  return fetch(`${baseUrl}/users/createstudent`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(bodyString)
  });
};

export const declineTeacherApi = bodyString => {
  return fetch(`${baseUrl}/users/permissions/decline`, {
    method: "POST",
    credentials: setCredentialsHeader(),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(bodyString)
  });
};

export const editAdminApi = bodyString => {
  return fetch(`${baseUrl}/users/edit/admin`, {
    method: "POST",
    credentials: setCredentialsHeader(),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(bodyString)
  });
};

export const editParentApi = bodyString => {
  return fetch(`${baseUrl}/users/edit/parent`, {
    method: "POST",
    credentials: setCredentialsHeader(),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(bodyString)
  });
};

export const editStudentApi = bodyString => {
  return fetch(`${baseUrl}/users/students/edit`, {
    method: "POST",
    credentials: setCredentialsHeader(),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(bodyString)
  });
};

export const editTeacherApi = bodyString => {
  return fetch(`${baseUrl}/users/edit/teacher`, {
    method: "POST",
    credentials: setCredentialsHeader(),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(bodyString)
  });
};

export const editUserDetailsApi = body => {
  return fetch(`${baseUrl}/users/profile/update`, {
    method: "POST",
    credentials: setCredentialsHeader(),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  });
};

export const logoutApi = () => {
  return fetch(`${baseUrl}/users/logout`, {
    method: "POST",
    credentials: setCredentialsHeader()
  });
};

export const getPasswordApi = bodyString => {
  return fetch(`${baseUrl}/users/view`, {
    method: "POST",
    credentials: setCredentialsHeader(),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(bodyString)
  });
};

export const getStudentPassword = bodyString => {
  return fetch(`${baseUrl}/users/student/view`, {
    method: "POST",
    credentials: setCredentialsHeader(),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(bodyString)
  });
};

export const getUserDetailsApi = id => {
  return fetch(`${baseUrl}/users/profile/view`, {
    method: "POST",
    credentials: setCredentialsHeader(),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      id
    })
  });
};

export const sendEmailApi = bodyString => {
  return fetch(`${baseUrl}/users/token`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(bodyString)
  });
};

export const checkUser = () => {
  return fetch(`${baseUrl}/users/check`, {
    method: "POST",
    credentials: setCredentialsHeader()
  });
};