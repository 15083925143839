import React from 'react';
import { DropdownMenu, DropdownItem } from 'reactstrap';
import qs from 'query-string';

const NotificationsList = ({notifications, hasNotifications, history, user, subjectNames}) => {

  return (
    <DropdownMenu className="notification-dropdown" right>
      {hasNotifications ? (
        notifications.map(n => {
          const query = qs.stringify({
            subjectId: n.subject,
            studentId: user.data.role === 'student' ? user.data.userByRole : n.sender
          });
          const subjectName = n.subjectName && subjectNames ? subjectNames[n.subjectName] + ' - ' : '';
          const studentName = n.studentName ? ' - ' + n.studentName : '';
          return (
            <DropdownItem
            key={n._id}
            // onClick={() => {}}
            >
            <div className='header-notify'>
              <div className='lesson-link' onClick={() => {
                history.push(`/dashboard/my_subjects/lessons/${n.lesson}?${query}`);
                }}>
                <h4>{n.message}</h4>
                <p>{`${subjectName}${n.lessonTitle}${studentName}`}</p>
              </div>
              {/* <div className='remove-notification'>
                Close
              </div> */}
            </div>
          </DropdownItem>
        );})
      ) : (
        <DropdownItem>
          <div className='header-notify'>
            <h4>Повідомлень немає</h4>
          </div>
        </DropdownItem>
      )}
    </DropdownMenu>
  );
};

export default NotificationsList;