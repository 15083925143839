import { ANNUALS_ADD } from '../actions/type'

const initialState = []

const annuals = (state = initialState, action) => {
  switch (action.type) {    
    case ANNUALS_ADD:
      const stateCopy = [...state]
      let index = -1
      stateCopy.some((a, i) => a.studentId === action.payload.studentId && (index = i))
      if (index !== -1) {
        stateCopy[index] = action.payload
      } else {
        stateCopy.push(action.payload)
      }
      return stateCopy;
  
    default:
      return state
  }
}

export default annuals