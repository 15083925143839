import { USERS_ADD } from '../actions/type'

const initialState = {
  data: []
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case USERS_ADD:
      const isHas = state.data.some(u => u._id === action.payload._id)
      if (!isHas) {
        return {
          ...state,
          data: [...state.data, action.payload]
        }
      } else {
        return state
      }
  
    default:
      return state
  }
}

export default users