import { RESULTS_ADD } from '../actions/type' 

const initialState = []

const results = (state = initialState, action) => {
  switch (action.type) {
    case RESULTS_ADD:
      const stateCopy = [...state]
      let index = -1
      stateCopy.some((r, i) => r.studentId === action.payload.studentId && (index = i))
      if (index !== -1) {
        stateCopy[index] = action.payload
      } else {
        stateCopy.push(action.payload)
      }
      return stateCopy;
  
    default:
      return state
  }
}

export default results