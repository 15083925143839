import { 
  SET_IS_DASHBOARD, 
  UNSET_IS_DASHBOARD,
  PUSHWORKER_ADD_SUBSCRIPTION, 
  SET_SEMESTER,
  SET_IS_LOGIN_PAGE,
  SET_IS_NOT_LOGIN_PAGE,
  SET_TEACHERS,
  SET_STUDENTS,
  SET_LESSONS,
  SET_ADMINS,
  SET_PARENTS
} from '../actions/type'

const initialState = {
  isDashboard: false,
  subscriptionSent: false,
  semester: 0,
  isLoginPage: false,

  isLoadedTeachers: false,
  isLoadedAdmins: false,
  isLoadedStudents: false,
  isLoadedParents: false,
  isLoadedLessons: false,
}

const modes = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_DASHBOARD:
      return {
        ...state,
        isDashboard: true
      }

    case UNSET_IS_DASHBOARD:
      return {
        ...state,
        isDashboard: false
      }

    case PUSHWORKER_ADD_SUBSCRIPTION:
      return {
        ...state,
        subscriptionSent: true
      }

    case SET_SEMESTER:
      return {
        ...state,
        semester: action.payload
      }

    case SET_IS_LOGIN_PAGE:
      return {
        ...state,
        isLoginPage: true
      }

    case SET_IS_NOT_LOGIN_PAGE:
      return {
        ...state,
        isLoginPage: false
      }

    case SET_TEACHERS:
      return {
        ...state,
        isLoadedTeachers: true
      }

    case SET_ADMINS:
      return {
        ...state,
        isLoadedAdmins: true
      }

    case SET_STUDENTS:
      return {
        ...state,
        isLoadedStudents: true
      }

    case SET_LESSONS:
      return {
        ...state,
        isLoadedLessons: true
      }

    case SET_PARENTS:
      return {
        ...state,
        isLoadedParents: true
      }
  
    default:
      return state
  }
}

export default modes