import { ADD_MESSAGE, ADD_FRESH_MESSAGE } from "../actions/type";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return [...state, ...action.payload];

    case ADD_FRESH_MESSAGE:
      return [...action.payload];

    default:
      return state;
  }
};
