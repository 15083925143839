import { 
  SET_IMAGES_ALL,
  SET_IMAGES_LIMITED,
  IMAGE_ADD_ONE,
  IMAGE_DELETE_ONE,
  IMAGE_SET_ISFILTER, 
  IMAGE_SET_FILTER_BY_SCHOOLCLASS,
  IMAGE_SET_FILTER_BY_SUBJEC_NAME,
  IMAGE_SET_FILTER_BY_DESCRIPTION
} from '../actions/type'

export const limitPerRequest = 20

const initialState = {
  data: [],
  filterBySchoolClass: '',
  filterBySubjectName: '',
  filterByDescription: '',
  isFilter: false,
  skip: 0,
  limit: limitPerRequest,
  noMore: false
}

const images = (state = initialState, action) => {
  switch (action.type) {
    case SET_IMAGES_ALL:
      return {
        ...state,
        data: action.payload
      }  
    case SET_IMAGES_LIMITED:
      return {
        ...state,
        data: [...state.data, ...action.payload],
        skip: state.skip + limitPerRequest,
        noMore: !(action.payload.length > 0)
      }

    case IMAGE_ADD_ONE:
      const newdata = [action.payload].concat(state.data)
      return {
        ...state,
        data: newdata,
        skip: state.skip === 0 ? 0 : state.skip + 1
      }

    case IMAGE_DELETE_ONE:
      const newdata1 = state.data.filter(image => image._id !== action.payload)
      return {
        ...state,
        data: newdata1,
        skip: state.skip === 0 ? 0 : state.skip - 1
      }

    case IMAGE_SET_ISFILTER:
      if (!action.payload) {
        return {
          ...state,
          isFilter: action.payload,
          filterBySchoolClass: '',
          filterBySubjectName: '',
          filterByDescription: '',
          skip: 0,
          limit: limitPerRequest,
        }
      } else {
        return {
          ...state,
          isFilter: action.payload,
          skip: 0,
          limit: limitPerRequest,
        }
      }

    case IMAGE_SET_FILTER_BY_SCHOOLCLASS:
      return {
        ...state,
        filterBySchoolClass: action.payload,
        skip: 0,
        limit: limitPerRequest,
      }

    case IMAGE_SET_FILTER_BY_SUBJEC_NAME:
      return {
        ...state,
        filterBySubjectName: action.payload,
        skip: 0,
        limit: limitPerRequest,
      }

    case IMAGE_SET_FILTER_BY_DESCRIPTION:
      return {
        ...state,
        filterByDescription: action.payload,
        skip: 0,
        limit: limitPerRequest,
      }
  
    default:
      return state
  }
}

export default images