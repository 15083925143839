export const SET_LANGUAGE = "SET_LANGUAGE";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_FRESH_MESSAGE = "ADD_FRESH_MESSAGE";
export const TOGGLE_CHAT = "TOGGLE_CHAT";
export const OPEN_CHAT = "OPEN_CHAT";
export const CLOSE_CHAT = "CLOSE_CHAT";
export const OPEN_USER_CHAT = "OPEN_USER_CHAT";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const READ_NOTIFICATION = "READ_NOTIFICATION";
export const READ_ALL_NOTIFICATION = "READ_ALL_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const HAS_NEW_NOTIFICATION = "HAS_NEW_NOTIFICATION";
export const NO_NEW_NOTIFICATION = "NO_NEW_NOTIFICATION";
export const SET_VIEW_CONTENT = "SET_VIEW_CONTENT";
export const GET_VIEW_CONTENT = "GET_VIEW_CONTENT";

export const SET_USER = "SET_USER";
export const DELETE_USER = "DELETE_USER";

export const SET_TEACHERS = "SET_TEACHERS";
export const SET_ADMINS = 'SET_ADMINS'
export const SET_PARENTS = "SET_PARENTS";

export const SET_STUDENTS = "SET_STUDENTS";

export const SET_LESSONS = "SET_LESSONS";
export const UPDATE_LESSON_BY_ID = "UPDATE_LESSON_BY_ID";
export const CREATE_LESSON = "CREATE_LESSON";

export const SET_CLASS_SHEDULE = "SET_CLASS_SHEDULE";
export const CLASSSHEDULE_ADD = 'CLASSSHEDULE_ADD'

export const LESSON_CONTENT_ADD_FORM_ITEM = 'LESSON_CONTENT_ADD_FORM_ITEM';
export const LESSON_CONTENT_MOVE_FORM_ITEM = 'LESSON_CONTENT_MOVE_FORM_ITEM';
export const LESSON_CONTENT_SET_EDITING_FORM_ITEM_INDEX = 'LESSON_CONTENT_SET_EDITING_FORM_ITEM_INDEX';
export const LESSON_CONTENT_SAVE_FORM_ITEM_CHANGES = 'LESSON_CONTENT_SAVE_FORM_ITEM_CHANGES';
export const LESSON_CONTENT_SET_FORM_ITEM_DELTA = 'LESSON_CONTENT_SET_FORM_ITEM_DELTA';
export const LESSON_CONTENT_DELETE_FORM_ITEM = 'LESSON_CONTENT_DELETE_FORM_ITEM';
export const LESSON_CONTENT_SET_FORM_ITEMS = 'LESSON_CONTENT_SET_FORM_ITEMS';
export const LESSON_CONTENT_SAVE = 'LESSON_CONTENT_SAVE';
export const LESSON_CONTENT_SET_IS_SAVED = 'LESSON_CONTENT_SET_IS_SAVED';
export const LESSON_CONTENT_BLOCK_COPY = 'LESSON_CONTENT_BLOCK_COPY';
export const LESSON_CONTENT_BLOCK_PASTE = 'LESSON_CONTENT_BLOCK_PASTE'; 

export const LESSON_CONTENT_SET_LESSON_ID_OF_COPIED = 'LESSON_CONTENT_SET_LESSON_ID_OF_COPIED';
export const LESSON_CONTENT_DELETE_LESSON_ID_OF_COPIED = 'LESSON_CONTENT_DELETE_LESSON_ID_OF_COPIED';

export const LESSON_CONFIRM = 'LESSON_CONFIRM'
export const LESSON_UPDATE = 'LESSON_UPDATE'
export const LESSON_ADD = 'LESSON_ADD'

export const SCHOOLCLASS_ADD = 'SCHOOLCLASS_ADD'
export const SCHOOLCLASS_UPDATE = 'SCHOOLCLASS_UPDATE'
export const SCHOOLCLASS_ADD_STUDENTS_START = 'SCHOOLCLASS_ADD_STUDENTS_START'
export const SCHOOLCLASS_ADD_STUDENTS = 'SCHOOLCLASS_ADD_STUDENTS'
export const SCHOOLCLASS_ADD_STUDENTS_END = 'SCHOOLCLASS_ADD_STUDENTS_END'
export const SCHOOLCLASS_SET_ALL = 'SCHOOLCLASS_SET_ALL' 
export const SCHOOLCLASS_CHANGE_STUDENT_CLASS = 'SCHOOLCLASS_CHANGE_STUDENT_CLASS' 

export const SET_IS_DASHBOARD = 'SET_IS_DASHBOARD'
export const UNSET_IS_DASHBOARD = 'UNSET_IS_DASHBOARD'

export const SET_SUBJECTS = 'SET_SUBJECTS'
export const SET_SUBJECTS_LIMITED = 'SET_SUBJECTS_LIMITED'
export const SUBJECT_SET_COPIED_LESSONS = 'SUBJECT_SET_COPIED_LESSONS'
export const SUBJECTS_SET_ONE = 'SUBJECTS_SET_ONE'
export const SUBJECTS_REPLACE_ONE = 'SUBJECTS_REPLACE_ONE'
export const SUBJECT_REPLACE_LESSON = 'SUBJECT_REPLACE_LESSON' 
export const SUBJECT_DELETE_LESSON = 'SUBJECT_DELETE_LESSON'
export const SUBJECTS_SET_SEMESTER = 'SUBJECTS_SET_SEMESTER'

export const STUDENT_ADD_RESULT = 'STUDENT_ADD_RESULT'
export const SET_RESULTS = 'SET_RESULTS'

export const PUSHWORKER_ADD_SUBSCRIPTION = 'PUSHWORKER_ADD_SUBSCRIPTION'

export const SET_SEMESTER = 'SET_SEMESTER'

export const SET_ANNUALS = 'SET_ANNUALS'

export const SET_SUBJECTNAMES = 'SET_SUBJECTNAMES' 
export const SUBJECTNAME_ADD_START = 'SUBJECTNAME_ADD_START'
export const SUBJECTNAME_ADD_END = 'SUBJECTNAME_ADD_END'

export const SET_IS_LOGIN_PAGE = 'SET_IS_LOGIN_PAGE'
export const SET_IS_NOT_LOGIN_PAGE = 'SET_IS_NOT_LOGIN_PAGE'

export const SET_AVATAR = 'SET_AVATAR'

export const SET_EDITED_PROFILE_DATA = 'SET_EDITED_PROFILE_DATA'

export const USERS_ADD = 'USERS_ADD'

export const SET_IMAGES_ALL = 'SET_IMAGES_ALL'
export const SET_IMAGES_LIMITED = 'SET_IMAGES_LIMITED'
export const IMAGE_ADD_ONE = 'IMAGE_ADD_ONE'
export const IMAGE_DELETE_ONE = 'IMAGE_DELETE_ONE'
export const IMAGE_SET_ISFILTER = 'IMAGE_SET_ISFILTER'
export const IMAGE_SET_FILTER_BY_SCHOOLCLASS = 'IMAGE_SET_FILTER_BY_SCHOOLCLASS'
export const IMAGE_SET_FILTER_BY_SUBJEC_NAME = 'IMAGE_SET_FILTER_BY_SUBJEC_NAME'
export const IMAGE_SET_FILTER_BY_DESCRIPTION = 'IMAGE_SET_FILTER_BY_DESCRIPTION'

export const CONFIRMATION_SET_ISFILTER = 'CONFIRMATION_SET_ISFILTER'
export const CONFIRMATION_SET_FILTER_BY_SCHOOLCLASS = 'CONFIRMATION_SET_FILTER_BY_SCHOOLCLASS'
export const CONFIRMATION_SET_FILTER_BY_SUBJEC_NAME = 'CONFIRMATION_SET_FILTER_BY_SUBJEC_NAME'

export const LESSONS_DONE_ADD = 'LESSONS_DONE_ADD'

export const RESULTS_ADD = 'RESULTS_ADD'

export const ANNUALS_ADD = 'ANNUALS_ADD'

export const ASSESSMENTS_SET_IS_OPEN_SCHOOL_CLASS_ID = 'ASSESSMENTS_SET_IS_OPEN_SCHOOL_CLASS_ID'
export const ASSESSMENTS_SET_IS_OPEN_STUDENT_ID = 'ASSESSMENTS_SET_IS_OPEN_STUDENT_ID'
export const ASSESSMENTS_SET_SCHOOL_CLASS_TYPE = 'ASSESSMENTS_SET_SCHOOL_CLASS_TYPE'