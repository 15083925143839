import React, { Component } from "react";
import { connect } from 'react-redux'

class SiteFooter extends Component {
  render() {

    if (this.props.isLoginPage) return null

    return (
      <footer className="text-white">
        <div className="d-flex justify-content-between">
          <div className="footer-left">
            <div className="footer-logo">
              <a href="/">PATPROFI</a>
            </div>
            <div className="footer-address">
              <p>
                вул. М. Максимовича, 10 (О. Трутенка)
                <br />
                068 100 40 20 | 099 100 4
              </p>
            </div>
          </div>
          <div className="footer-right pr-0 pl-0 col-md-4 justify-content-md-end justify-content-center">
            <div className="icon">
              <button>
                <img
                  alt={"insta"}
                  src={require("../assets/icons/instagram.svg")}
                />
              </button>
            </div>
            <div className="icon">
              <button>
                <img
                  alt={"twitter"}
                  src={require("../assets/icons/twitter.svg")}
                />
              </button>
            </div>
            <div className="icon">
              <button>
                <img
                  alt={"facebook"}
                  src={require("../assets/icons/facebook.svg")}
                />
              </button>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = state => ({
  isLoginPage: state.modes.isLoginPage
})

export default connect(mapStateToProps) (SiteFooter);
