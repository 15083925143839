import { 
  SET_SUBJECTS, 
  SUBJECT_SET_COPIED_LESSONS, 
  SET_SUBJECTS_LIMITED, 
  SUBJECTS_SET_ONE, 
  SUBJECTS_REPLACE_ONE,
  SUBJECT_REPLACE_LESSON,
  SUBJECT_DELETE_LESSON,
  SUBJECTS_SET_SEMESTER
} from "../actions/type";

const LIMIT = 10

const initialState = {
  data: [],
  isLoaded: false,
  isCopiedLessons: false,
  skip: 0,
  limit: LIMIT,
  noMore: false,
  copied: {
    subjectId: '',
    semester: ''
  },
  semesters: {}
}

const subjects = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_SUBJECTS:
      return {
        ...state,
        data: payload,
        isLoaded: true
      }

    case SUBJECT_SET_COPIED_LESSONS:
      return {
        ...state,
        isCopiedLessons: true,
        copied: payload
      }

    case SET_SUBJECTS_LIMITED:
      const newdata = [...state.data]
      payload.forEach(s => {
        if (!newdata.some(subject => subject._id === s._id)) {
          newdata.push(s)
        }
      });
      return {
        ...state,
        data: newdata,
        skip: state.skip + payload.length,
        limit: state.limit + payload.length,
        noMore: payload.length === 0
      }

    case SUBJECTS_SET_ONE:
      const newdata1 = [...state.data]
      newdata1.push(payload)
      // if (!newdata1.some(s => s._id === payload._id)) {
      // }
      return {
        ...state,
        data: newdata1
      }

    case SUBJECTS_REPLACE_ONE:
      const newdata2 = [...state.data].map(s => {
        if (s._id === payload._id) return payload
        return s
      })
      return {
        ...state,
        data: newdata2
      }

    case SUBJECT_REPLACE_LESSON:
      let br = false
      const newData3 = [...state.data].map(s => {
        if (br) return s
        s.lessons.forEach((ls, i) => {
          if (ls._id === payload._id) {
            s.lessons.splice(i, 1, payload)
            br = true
          }
        })
        return s
      })
      return {
        ...state,
        data: newData3
      }

    case SUBJECT_DELETE_LESSON:
      let br1 = false
      const newData4 = [...state.data].map(s => {
        if (br1) return s
        s.lessons.forEach((ls, i) => {
          if (ls._id === payload) {
            s.lessons.splice(i, 1)
            br1 = true
          }
        })
        return s
      })
      return {
        ...state,
        data: newData4
      }

    case SUBJECTS_SET_SEMESTER:
      const semesters = {...state.semesters}
      semesters[action.payload.subjectId] = action.payload.semester
      return {
        ...state,
        semesters
      } 

    default:
      return state;
  }
}

export default subjects