import { ASSESSMENTS_SET_IS_OPEN_SCHOOL_CLASS_ID, ASSESSMENTS_SET_IS_OPEN_STUDENT_ID, ASSESSMENTS_SET_SCHOOL_CLASS_TYPE } from '../actions/type';

const initialState = {
  isOpenSchoolClassId: '',
  isOpenStudentId: '',
  selectedSchoolClassType: ''
};

const annuals = (state = initialState, action) => {
  switch (action.type) {    
    case ASSESSMENTS_SET_IS_OPEN_SCHOOL_CLASS_ID:
      if (state.isOpenSchoolClassId === action.payload) {
        return {
          ...state,
          isOpenSchoolClassId: '',
          isOpenStudentId: ''
        };
      } else {
        return {
          ...state,
          isOpenSchoolClassId: action.payload
        };
      }

    case ASSESSMENTS_SET_IS_OPEN_STUDENT_ID:
      if (state.isOpenStudentId === action.payload) {
        return {
          ...state,
          isOpenStudentId: ''
        };
      } else {
        return {
          ...state,
          isOpenStudentId: action.payload
        };
      }

    case ASSESSMENTS_SET_SCHOOL_CLASS_TYPE:
      return {
        ...state,
        selectedSchoolClassType: action.payload
      };
  
    default:
      return state;
  }
};

export default annuals;