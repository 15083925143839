import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import classNames from "classnames";
import { Icon } from "react-icons-kit";
import { bell } from "react-icons-kit/iconic/";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import {
  setLanguage,
  addMessages,
  addFreshMessages,
  openUserChat,
} from "../actions";
import { deleteUserAction } from '../actions/userActions'
import { unsetDashboard } from '../actions/modesActions'
// import { generateChatNotification } from "../services/notificationHelper";
import logoSVG from "../assets/logo.svg";
import globe from "../assets/icons/globe-white.svg";
import user_icon from "../assets/icons/user-white.svg";
import video_camera_icon from '../assets/icons/video-camera.png'
import { languageArray } from "../services/Constants";
import { logoutApi } from "../services/api/usersApis";
// import { getMessagesByReceiverApi } from "../services/api/messagesApis";
import { getNotificationsByUserAction } from '../actions/notificationActions'
import { selectSubjectNames } from '../selectors/subjectNameSelectors'
import {
  getTranslations,
  updateLangTag
  // getLanguage
} from "../services";
import NotificationsList from './NotificationsList'

class MainHeader extends Component {
  constructor(props) {
    super(props);
    this.notificationtoggle = this.notificationtoggle.bind(this);
    this.acctoggle = this.acctoggle.bind(this);
    this.lantoggle = this.lantoggle.bind(this);
    this.changeLang = this.changeLang.bind(this);
    this.logOut = this.logOut.bind(this);
    this.state = {
      landropdownOpen: false,
      accdropdownOpen: false,
      notificationOpen: false,
      activeLang: this.props.language,
      lang: languageArray,
      notificationList: [],
      allMsg: []
    };
  }
  componentDidMount() {
    this.getNewMessages();
    // this.setState({
    //   notificationList: [...getChatNotify(this.props.chatmessages)],
    // })
  }
  renderNotification(notifications) {
    console.log(notifications)
    return notifications.map(n => {
      return (
        <DropdownItem
          onClick={() => {}}
        >
          <div
            className='header-notify'
          >
            <h4>Є зміна в уроці</h4>
            <p>{'kjhlkjhklj'}</p>
          </div>
        </DropdownItem>
      );
    });
  }
  getNewMessages() {
    const { user } = this.props;
    if (user.isLoggedIn) {
      // getMessagesByReceiverApi(user.data.email)
      //   .then(res => res.json())
      //   .then(resJson => {
      //     const { status, messages } = resJson;
      //     if (status) {
      //       const newMessages = getNewMsg(messages);
      //       this.props.addNotification(generateChatNotification(newMessages));
      //       if (
      //         generateChatNotification(newMessages).find(el => !el.isRead)
      //       ) {
      //         this.props.hasNotification();
      //       }
      //       // this.setState({
      //       //   notificationList: [...this.state.notificationList, ...getChatNotify(newMessages)]
      //       // }
      //     }
      //   });
    }
  }
  acctoggle() {
    this.setState(prevState => ({
      accdropdownOpen: !prevState.accdropdownOpen
    }));
  }
  lantoggle() {
    this.setState(prevState => ({
      landropdownOpen: !prevState.landropdownOpen
    }));
  }
  notificationtoggle() {
    // this.props.hasNoNotification();
    if (!this.state.notificationOpen) {
      this.props.getNotificationsByUserAction(this.props.user.data._id)
    }
    this.setState(prevState => ({
      notificationOpen: !prevState.notificationOpen
    }));
  }
  changeLang(index) {
    const language = this.state.lang[index].code 
    const lang = ['en', 'ru', 'ua'].includes(language) ? language : 'ua'
    updateLangTag(lang);
    localStorage.setItem("language", lang);
    this.props.setLanguage(lang);
    // this.props.languageChanger();
  }
  logOut() {
    // this.props.setViewedContent([]);
    logoutApi().catch(() => {}).finally(() => this.props.history.push('/'))
    this.props.unsetDashboard()
    this.props.deleteUserAction()
  }

  render() {

    if (this.props.isLoginPage) return null

    const { language } = this.props;
    const langData = getTranslations(language, ["header", "labels"]);
    // const langDataLabels = getTranslations(language, "labels");
    // const langData = { langDataHeaders, ...langDataLabels };
    const {
      lang,
      landropdownOpen,
      accdropdownOpen,
      notificationOpen,
    } = this.state;
    const { user } = this.props
    const userRole = user.isLoggedIn && langData[user.data.role] ? langData[user.data.role] : "";

    const notifications = user.notifications
    const hasNotifications = Array.isArray(user.data.notifications) && user.data.notifications.length 
    
    return (
      <header className="main-header">
        <ReactTooltip id='tip-header'  />
        <div className={"container-fluid"}>
          <div className="top-header-row row justify-content-between">
            <div className="logo">
              <Link to={`${user.isLoggedIn ? '/dashboard' : '/'}`}>
                <img src={logoSVG} className="App-logo" alt="logo" />
              </Link>
            </div>
            <div className="logined-user-role">
              <span>{userRole}</span>
            </div>
            <div className="right-header d-flex align-items-center">
              <a href='https://www.uaschool.space/timetable/' target='_blank' rel='noopener noreferrer'>
                <div className="icon">
                  <img 
                    alt="user" 
                    src={video_camera_icon}  
                    data-tip={'Онлайн КОНСУЛЬТАЦІЇ'}
                    data-for='tip-header'
                  />
                </div>
              </a>
              <Dropdown isOpen={landropdownOpen} toggle={this.lantoggle}>
                <DropdownToggle tag="span" outline={0}>
                  <div className="icon">
                    <img alt="lang" src={globe} />
                  </div>
                </DropdownToggle>
                <DropdownMenu right>
                  {lang.map((lang, index) => (
                    <DropdownItem
                      key={index}
                      onClick={() => {
                        this.changeLang(index);
                      }}
                    >
                      <span>
                        <img alt={lang.code} src={lang.img} />{" "}
                      </span>
                      {lang.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              {user.isLoggedIn && (
                <Dropdown
                  isOpen={notificationOpen}
                  toggle={this.notificationtoggle}
                >
                  <DropdownToggle tag="span" outline={0}>
                    <div
                      className={classNames("icon", {
                        "has-new": hasNotifications
                      })}
                    >
                      <Icon icon={bell} />
                    </div>
                  </DropdownToggle>
                  <NotificationsList 
                    notifications={notifications} 
                    hasNotifications={hasNotifications}
                    history={this.props.history} 
                    user={this.props.user}
                    subjectNames={this.props.subjectNames}
                  />
                </Dropdown>
              )}
              {user.isLoggedIn ? (
                <Dropdown isOpen={accdropdownOpen} toggle={this.acctoggle}>
                  <DropdownToggle tag="span" outline={0}>
                    <div className="icon">
                      <img alt="user" src={user_icon} />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <Link to={`/dashboard/profile`}>
                      <DropdownItem
                        onClick={() => {
                          // this.logOut();
                          // window.location.pathname = "/dashboard/profile"
                        }}
                      >
                        {langData.profile}
                      </DropdownItem>
                    </Link>
                    <DropdownItem
                      onClick={() => {
                        this.logOut();
                      }}
                    >
                      {langData.logout}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              ) : (
                <Link to="/login/">
                  <div className="icon">
                    <img alt="user" src={user_icon} />
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      </header>
    );
  }
}
const mapStateToProps = state => {
  return {
    language: state.language,
    user: state.user,
    notifications: state.notifications,
    isLoginPage: state.modes.isLoginPage,
    subjectNames: selectSubjectNames(state)
  }
};
export default connect(
  mapStateToProps,
  {
    setLanguage,
    addMessages,
    addFreshMessages,
    openUserChat,
    deleteUserAction,
    unsetDashboard,
    getNotificationsByUserAction
  }
)(withRouter(MainHeader));
