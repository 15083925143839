import { createSelector } from 'reselect'

const getSubjectNames = state => state.subjectNames.data
const getSubjectNamesFiltered = state => state.subjectNames.data.filter(sn => !!sn.classNumbers.length)
const getLang = state => state.lang.lang
const getLangFromProps = (_, props) => props.language
const getSchoolClassNumber = (_, num) => num
const getSubjectByIdFromParamsId = (state, props) => state.subjects.data.find(s => s._id === props.match.params.id)
const getSchoolClassNumberBySchoolClassIdFromProps = (state, props) => {
  const schoolClass = state.schoolClass.classes.find(sc => sc._id === props.schoolClassId)
  return schoolClass ? schoolClass.classNumber : 0
}
const getSubjectNameId = (_, props) => props.subjectNameId

const filterAndGetSubjectNameOptions = (subjectNames, lang, classNumber) => {
  const filteredSubjectNames = subjectNames.filter(s => s.classNumbers.includes(classNumber))
  return filteredSubjectNames.map(s => ({value: s._id, label: s[lang]}))
} 

export const selectSubjectNameBySubjectIdFromParamsId = createSelector(
  [getSubjectNames, getLang, getSubjectByIdFromParamsId],
  (subjectNames, lang, subject) => {
    const subjectName = subject && subjectNames.find(sn => sn._id === subject.subjectName)
    return (subjectName && subjectName[lang]) || ''
  }
)

export const selectSubjectNamesFetched = createSelector(
  getSubjectNames,
  subjectNames => subjectNames
) 

export const selectSubjectNames = createSelector(
  [getSubjectNames, getLang],
  (subjectNames, lang) => {
    const obj = {}
    subjectNames.forEach(s => {
      obj[s._id] = s[lang]
    });
    return obj
  } 
)

export const selectSubjectNamesFiltered = createSelector(
  [getSubjectNamesFiltered, getLang],
  (subjectNames, lang) => {
    const obj = {}
    subjectNames.forEach(s => {
      obj[s._id] = s[lang]
    });
    return obj
  } 
)

export const selectSubjectNamesByLanguageFromProps = createSelector(
  [getSubjectNames, getLangFromProps],
  (subjectNames, lang) => {
    const obj = {}
    subjectNames.forEach(s => {
      obj[s._id] = s[lang]
    });
    return obj
  } 
)

export const selectSubjectNamesOptions = createSelector(
  [getSubjectNames, getLang],
  (subjectNames, lang) => subjectNames.map(s => ({value: s._id, label: s[lang]}))
)

export const selectSubjectNamesFilteredOptions = createSelector(
  [getSubjectNamesFiltered, getLang],
  (subjectNames, lang) => subjectNames.map(s => ({value: s._id, label: s[lang]}))
)

export const selectSubjectNamesOptionsByClassNumber = createSelector(
  [getSubjectNames, getLang, getSchoolClassNumber],
  filterAndGetSubjectNameOptions
)

export const selectSubjectNamesOptionsWithClassNumbers = createSelector(
  [getSubjectNames, getLang],
  (subjectNames, lang) => subjectNames.map(s => ({value: s._id, label: s[lang], classNumbers: s.classNumbers}))
)

export const selectSubjectNameOptionsBySchoolClassIdFromProps = createSelector(
  [getSubjectNames, getLang, getSchoolClassNumberBySchoolClassIdFromProps],
  filterAndGetSubjectNameOptions
)

export const selectSubjectNameOptionById = createSelector(
  [getSubjectNames, getLang, getSubjectNameId],
  (subjectNames, lang, subjectNameId) => {
    if (!subjectNameId) return ''
    const subjectName = subjectNames.find(s => s._id === subjectNameId) 
    return {value: subjectName._id, label: subjectName[lang]}
  }
)