export default {
  common: {
    users: 'Users',
    add: 'Add',
    update: 'Update',
    show_result: 'Show the result',
    save: "Save",
    save_and_go_back: 'Save and go back',
    upload: 'Upload',
    uploaded: 'Uploaded',
    no_file_choosen: 'No file choosen',
    video: 'Video',
    photo: 'Photo'
  },
  toolbar: {
    header: 'Header',
    paragraph: 'Paragraph',
    question_answer: 'The question with the correct answer',
    question_answers: 'The question with the correct answers',
    question_answer_photo: 'Питання з фото',
    answer_choice: 'Answer choice',
    find_compliance: 'Find compliance',
    question: 'Question',
    toolbox: 'Toolbox',
    score_for: 'Score for correct answer',
    correct_answer: 'Correct answer',
    release_to_add: 'Release to add',
    drag_here: 'Drag a box here',
    have_unsaved: 'You have unsaved changes, are you sure you want to leave?',
    create_by_yourself: 'Create by yourself',
    task_with_text_response: 'Task with text response',
    task_description: 'Task description',
    write_answer_here: 'Write answer here',
    columns: 'Columns',
    rows: 'Rows',
    placeholder: 'Placeholder',
    upload_file: 'Upload file',
    download_answer: 'Download answer',
  },
  header: {
    logo: require("../../assets/logo/SVG/logo(en).svg"),
    profile: "Profile",
    logout: "Log out"
  },
  banner: {
    USDE: "USDE",
    Biggest_online_school: "Biggest online school for distance education.",
    REGISTER_NOW: "REGISTER NOW",
    logo: require("../../assets/logo/SVG/logo-full(en).svg")
  },
  discipline: {
    more_than: "More than 100+ disciplines",
    our_service:
      "Our service provide you with extended school program for all grades and has a lot of additional materials."
  },
  labels: {
    start: 'START',
    authorization: 'Authorization',
    admin: "Admin",
    admins: "Admins",
    teacher: "Teacher",
    teachers: "Teachers",
    parent: "Parent",
    parents: "Parents",
    students: "Students",
    student: "Student",
    createAdmin: "Create Admin",
    editAdmin: "Edit Admin",
    newteachers: "New Teachers",
    createteacher: "Create Teacher",
    editteacher: "Edit Teacher",
    createparent: "Create Parent",
    editparent: "Edit Parent",
    createstudent: "Create Student",
    editstudent: "Edit Student",
    approve: "APPROVE",
    decline: "DECLINE",
    chat: "Chat",
    searchuser: "Search user",
    searchconversation: "Search conversation",
    email: "Email",
    password: "Password",
    save: "Save",
    hello: "Hello",
    opencalendar: "OPEN CALENDAR",
    progress: "Progress",
    todolist: "To do list",
    todo: "To do",
    completed: "Completed",
    tasks: "tasks",
    login: "Login",
    forgotpassword: "Forgot Password?",
    not_registered_yet: 'Not registered yet?',
    enterNameHere: "Enter name here",
    enterhere: "Enter here...",
    firstname: "First Name",
    lastname: "Last Name",
    phone: "Phone",
    description: "Description",
    disciplines: "Disciplines",
    profile: "Profile",
    avatar: "Avatar",
    username: "User Name",
    examplelogin: "example_login",
    createcontent: "Create content",
    createprogram: "Create program",
    createlesson: "Create lesson",
    title: "Title",
    withhometask: "With hometask",
    exam: "Exam",
    button: "BUTTON",
    selectyourdisciplines: "Select your disciplines...",
    content: "Content",
    name: "Name",
    date: "Date",
    search: "Search",
    filters: "Filters",
    public: "PUBLIC",
    hidden: "HIDDEN",
    archived: "ARCHIVED",
    enterDisciplineHere: "Enter discipline here...",
    enterTeacherHere: "Enter teacher here...",
    enterStatusHere: "Enter status here...",
    enterTagHere: "Enter tag here...",
    program: "Program",
    language: "Language",
    programs: "Programs",
    lessons: "Lessons",
    task: "Task",
    livelessons: "Live Lessons",
    from: "From",
    to: "To",
    inCalendar: "In Calendar",
    registration: "Registration",
    I_agree_with: "I agree with",
    terms: "Terms",
    and: "and",
    policies: "Policies",
    register: "Register",
    forget_password: "Forget Password",
    or_login: "or Login",
    reset_password: "Reset Password",
    select_student: 'Select student'
  },
  calendar: {
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
    Exams: "Exams",
    Lessons: "Lessons",
    Online_Lessons: "Online Lessons",
    Filter_disciplines: "Filter disciplines",
    Year: "Year",
    Month: "Month",
    Week: "Week",
    МО: "МО",
    TU: "TU",
    WE: "WE",
    TH: "TH",
    FR: "FR",
    SA: "SA",
    SU: "SU",

    МОN: "МОN",
    TUE: "TUE",
    WED: "WED",
    THU: "THU",
    FRI: "FRI",
    SAT: "SAT",
    SUN: "SUN"
  },
  msgs: {
    login_success: 'На Старт, Увага, ВПЕРЕД до Знань! :-)',
    success: 'Success',
    sure_delete: 'Are you sure, you want to delete?',
    sure_delete_lesson: 'Are you sure you want to delete the lesson?',
    delete_subject_only: 'Delete subject only',
    delete_subject_with_lessons: 'Delete subject with lessons'
  },
  forms: {
    set_new_password: "Set new password",
    current_password: "Current password",
    new_password: "New password",
    re_new_password: "Repeat new password",
    click_to_set_photo: "Click to set photo",
    write_password: "Введіть пароль",
    re_password: 'Повторіть пароль',
    set_your_account_password: 'Встановити пароль до свого облікового запису',
    set_password: 'Встановити пароль'
  }
};
