import { SET_LANGUAGE } from "../actions/type";

const initialState = localStorage.getItem("user_language") || localStorage.getItem("language") || "ua";

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.payload;

    default:
      return state;
  }
};
