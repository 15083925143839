import { 
  CONFIRMATION_SET_ISFILTER,
  CONFIRMATION_SET_FILTER_BY_SUBJEC_NAME,
  CONFIRMATION_SET_FILTER_BY_SCHOOLCLASS,
} from '../actions/type'

const initialState = {
  filterBySchoolClass: '',
  filterBySubjectName: '',
  isFilter: false,
}

const confirmation = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case CONFIRMATION_SET_ISFILTER:
      if (!payload) {
        return {
          ...state,
          isFilter: payload,
          filterBySchoolClass: '',
          filterBySubjectName: '',
        }
      } else {
        return {
          ...state,
          isFilter: payload
        }
      }

    case CONFIRMATION_SET_FILTER_BY_SCHOOLCLASS:
      return {
        ...state,
        filterBySchoolClass: payload
      }

    case CONFIRMATION_SET_FILTER_BY_SUBJEC_NAME:
      return {
        ...state,
        filterBySubjectName: payload
      }
  
    default:
      return state
  }
}

export default confirmation