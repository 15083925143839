import { LESSONS_DONE_ADD } from "../actions/type";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case LESSONS_DONE_ADD:
      const stateCopy = [...state]
      let index = -1
      stateCopy.some((s, i) => s.student === action.payload.student && (index = i))
      if (index !== -1) {
        stateCopy[index] = action.payload
      } else {
        stateCopy.push(action.payload)
      }
      return stateCopy;

    default:
      return state;
  }
};