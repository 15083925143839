import {
  SET_LANGUAGE,
  ADD_MESSAGE,
  ADD_FRESH_MESSAGE,
  TOGGLE_CHAT,
  OPEN_CHAT,
  CLOSE_CHAT,
  OPEN_USER_CHAT
} from "./type";

export const setLanguage = lang => ({
  type: SET_LANGUAGE,
  payload: lang
});
export const addMessages = messages => ({
  type: ADD_MESSAGE,
  payload: messages
});
export const addFreshMessages = messages => ({
  type: ADD_FRESH_MESSAGE,
  payload: messages
});
export const toggleChat = () => ({
  type: TOGGLE_CHAT
});
export const openChat = () => ({
  type: OPEN_CHAT
});
export const closeChat = () => ({
  type: CLOSE_CHAT
});
export const openUserChat = selectedUser => ({
  type: OPEN_USER_CHAT,
  payload: selectedUser
});