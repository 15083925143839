import dashboard_menu_icon from "../assets/icons/menu/dashboard_menu_icon.svg";
import user_management_menu_icon from "../assets/icons/menu/user_management_menu_icon.svg";
import classShedule_menu_icon from "../assets/icons/menu/classShedule_menu_icon.svg";

import UserManage_icon from "../assets/icons/user_manage.svg";
import ClassManage_icon from "../assets/icons/user_edit.svg";
// import ClassConstructor_icon from "../assets/icons/class_constructor.svg";
import WorkPlan_icon from "../assets/icons/work-plan.svg";
import ProgramConstruct_icon from "../assets/icons/plan_edit.svg";
import ProblemSolvingIcon from "../assets/icons/problem-solving.svg";
import Analitics_icon from "../assets/icons/analitics.svg";

const names = {
  en: {
    Dashboard: "Dashboard",
    User_management: "User management",
    Users: 'Users',
    My_subjects: "My subjects",
    My_courses: "My courses",
    Class_shedule: "Class shedule",
    Work_plan: "Calendar plan",
    Problem_solving: "Problem solving",
    Class_constructor: "Class constructor",
    Assessments: "Assessments",
    Subjects_Teachers_of_Class: "Subjects / Teachers",
    Analitics: "Analitics",
    AddEditSubject: "Subject constructor",
    AddEditLesson: 'Lesson constructor',
    Confirmation: 'Confirmation',
    CheckTasks: 'Check tasks',
    Images: 'Images'
    // ElectivesCurses: "Факультативи/курси",
    // ElectiveConstructor: "Формування факультативів",
  },

  ru: {
    Dashboard: "Панель управления",
    User_management: "Управление пользователями",
    Users: 'Пользователи',
    My_subjects: "Мои предметы",
    My_courses: "Мои Факультативы",
    Class_shedule: "Расписание занятий",
    Work_plan: "Календарный план",
    Problem_solving: "Решение споров",
    Class_constructor: "Конструктор классов",
    Assessments: "Оценки",
    Subjects_Teachers_of_Class: "Предметы / Учителя",
    Analitics: "Аналитика",
    AddEditSubject: "Конструктор темы",
    AddEditLesson: 'Lesson constructor',
    Confirmation: 'Confirmation',
    CheckTasks: 'Проверка заданий',
    Images: 'Images'
    // ElectivesCurses: "Факультативи/курси",
    // ElectiveConstructor: "Формування факультативів",
  },

  ua: {
    Dashboard: "Панель управління",
    User_management: "Керування користувачами",
    Users: 'Користувач',
    My_subjects: "Мої предмети",
    My_courses: "Мої Факультативи",
    Class_shedule: "Розклад занять",
    Work_plan: "Календарний план",
    Problem_solving: "Вирішення проблем",
    Class_constructor: "Конструктор класів",
    Assessments: "Оцінка",
    Subjects_Teachers_of_Class: "Предмети / Вчителі",
    Analitics: "Аналітика",
    AddEditSubject: "Конструктор теми",
    AddEditLesson: 'Конструктор уроку',
    Confirmation: 'Підтвердження',
    CheckTasks: 'Перевірка завдань',
    Images: 'Зображення'
    // ElectivesCurses: "Факультативи/курси",
    // ElectiveConstructor: "Формування факультативів"
  }
}

const item = [
  {
    name: "",
    key: "Dashboard",
    link: "/dashboard",
    icon: dashboard_menu_icon,
    level: 5
  },
  {
    name: "",
    key: "User_management",
    link: "/dashboard/User_management",
    icon: user_management_menu_icon,
    level: 2
  },
  {
    name: "",
    key: "Users",
    link: "/dashboard/Users",
    icon: user_management_menu_icon,
    level: 2
  },
  {
    name: "",
    key: "My_subjects",
    link: "/dashboard/my_subjects",
    icon: UserManage_icon,
    level: 5
  },
  {
    name: "",
    key: "My_courses",
    link: "/dashboard/my_courses",
    icon: UserManage_icon,
    level: 5
  },
  {
    name: "",
    key: "Class_shedule",
    link: "/dashboard/class_shedule",
    icon: classShedule_menu_icon,
    level: 3
  },
  {
    name: "",
    key: "Work_plan",
    link: "/dashboard/work_plan",
    icon: WorkPlan_icon,
    level: 3
  },
  {
    name: "",
    key: "Problem_solving",
    link: "/dashboard/Problem_solving",
    icon: ProblemSolvingIcon,
    level: 5
  },
  {
    name: "",
    key: "Class_constructor",
    link: "/dashboard/Class_constructor",
    icon: ClassManage_icon,
    level: 2
  },
  {
    name: "",
    key: "Assessments",
    link: "/dashboard/Assessments",
    icon: ClassManage_icon,
    level: 3
  },
  {
    name: "",
    key: "Subjects_Teachers_of_Class",
    link: "/dashboard/Subjects-Teachers-of-Class",
    icon: ClassManage_icon,
    level: 2
  },
  {
    name: "",
    key: "Analitics",
    link: "/dashboard/Analitics",
    icon: Analitics_icon,
    level: 5
  },
  {
    name: "",
    key: "AddEditSubject",
    link: "/dashboard/add-edit-subject",
    icon: ProgramConstruct_icon,
    level: 2
  },
  {
    name: "",
    key: "AddEditLesson",
    link: "/dashboard/add-edit-lesson",
    icon: ProgramConstruct_icon,
    level: 3
  },
  {
    name: "",
    key: "Confirmation",
    link: "/dashboard/confirmation",
    icon: ProgramConstruct_icon,
    level: 1
  },
  {
    name: "",
    key: "CheckTasks",
    link: "/dashboard/check-tasks",
    icon: ProgramConstruct_icon,
    level: 3
  },
  {
    name: "",
    key: "Images",
    link: "/dashboard/images",
    icon: ProgramConstruct_icon,
    level: 3
  },
  // {
  //   name: "",
  //   key: "ElectivesCurses",
  //   link: "/dashboard/Electives_Curses",
  //   icon: ProgramConstruct_icon,
  //   level: 3
  // },
  // {
  //   name: "",
  //   key: "ElectiveConstructor",
  //   link: "/dashboard/Elective_constructor",
  //   icon: ProgramConstruct_icon,
  //   level: 3
  // }
]

const getDashboardMenuItems = lang => {
  const getItems = () => {
    item.forEach(menu => {
      menu.name = names[lang][menu.key]
    })
    return item
  }
  return getItems();
};

export default getDashboardMenuItems;
