export default {
  common: {
    users: 'Пользователи',
    add: 'Добавить',
    update: 'Обновить',
    show_result: 'Показать результат',
    save: "Сохранить",
    save_and_go_back: 'Сохранить и вернуться назад',
    upload: 'Загрузить',
    uploaded: 'Загружено',
    no_file_choosen: 'Файл не выбран',
    video: 'Видео',
    photo: 'Фотография'
  },
  toolbar: {
    header: 'Заголовок',
    paragraph: 'Параграф',
    question_answer: 'Вопрос с правильным ответом',
    question_answers: 'Вопрос с правильными ответами',
    question_answer_photo: 'Питання з фото',
    answer_choice: 'Вариант ответа',
    find_compliance: 'Підбір відповідності',
    question: 'Вопрос',
    toolbox: 'Ящик для инструментов',
    score_for: 'Оценка за правильный ответ',
    correct_answer: 'Правильный ответ',
    release_to_add: 'Отпустите, чтобы добавить',
    drag_here: 'Перетащите коробку сюда',
    have_unsaved: 'У вас есть несохраненные изменения, вы уверены, что хотите уйти?',
    create_by_yourself: 'Создать самому',
    task_with_text_response: 'Задача с текстовым ответом',
    task_description: 'Описание задания',
    write_answer_here: 'Напишите ответ здесь',
    columns: 'Столбцы',
    rows: 'Строки',
    placeholder: 'Заполнитель',
    upload_file: 'Загрузить файл',
    download_answer: 'Скачать ответ',
  },
  header: {
    logo: require("../../assets/logo/SVG/logo(ru).svg"),
    profile: "Профиль",
    logout: "Выйти"
  },
  banner: {
    USDE: "FOCUS",
    Biggest_online_school: "Крупнейшая онлайн-школа дистанционного обучения.",
    REGISTER_NOW: "ЗАРЕГИСТРИРОВАТЬСЯ",
    logo: require("../../assets/logo/SVG/logo-full(ru).svg")
  },
  discipline: {
    more_than: "Более 100 дисциплин",
    our_service:
      "Наш сервис предоставляет расширенную школьную программу для всех классов и имеет множество дополнительных материалов."
  },
  labels: {
    start: 'СТАРТ',
    authorization: 'Авторизация',
    admin: "Администратор",
    admins: "Администраторы",
    teacher: "Учитель",
    teachers: "Учителя",
    parent: "Родитель",
    parents: "Родители",
    students: "Ученики",
    student: "Ученик",
    createAdmin: "Создать админа",
    editAdmin: "Изменить админа",
    newteachers: "Новые учителя",
    createteacher: "СОЗДАТЬ УЧИТЕЛЯ",
    editteacher: "РЕДАКТИРОВАТЬ УЧИТЕЛЯ",
    createparent: "Создать Родителя",
    editparent: "Редактировать Родителя",
    createstudent: "Создать Ученика",
    editstudent: "Редактировать Ученика",
    approve: "УТВЕРДИТЬ",
    decline: "ОТКЛОНИТЬ",
    chat: "Чат",
    searchuser: "Поиск пользователя",
    searchconversation: "Поиск чата",
    email: "Email",
    password: "Пароль",
    save: "Сохранить",
    hello: "Привет",
    opencalendar: "КАЛЕНДАРЬ",
    progress: "Прогресс",
    todolist: "Список дел",
    todo: "Список дел",
    login: "Авторизация",
    completed: "Завершенный",
    forgotpassword: "Забыли пароль?",
    not_registered_yet: 'Еще не зарегистрированы?',
    tasks: "Задачи",
    enterNameHere: "Введите имя здесь",
    enterhere: "Введите здесь...",
    firstname: "Имя",
    lastname: "Фамилия",
    phone: "Телефон",
    description: "Описание",
    disciplines: "Дисциплины",
    profile: "Профиль",
    avatar: "Аватар",
    username: "Имя пользователя",
    examplelogin: "example_login",
    createcontent: "Создать контент",
    createprogram: "Создать программу",
    createlesson: "Создать Урок",
    title: "Заголовок",
    withhometask: "С домашним заданием",
    exam: "Экзамен",
    button: "КНОПКА",
    selectyourdisciplines: "Введите дисциплину здесь...",
    content: "Содержание",
    name: "Название",
    date: "Дата",
    search: "Поиск",
    filters: "Фильтры",
    public: "ПУБЛИЧНЫЙ",
    hidden: "СКРЫТЫЙ",
    archived: "АРХИВИРОВАН",
    enterDisciplineHere: "Введите дисциплину здесь...",
    enterTeacherHere: "Введите учителя здесь...",
    enterStatusHere: "Введите статус здесь...",
    enterTagHere: "Введите тег здесь...",
    program: "Программа",
    language: "Язык",
    programs: "Программы",
    lessons: "Уроки",
    task: "Задача",
    livelessons: "Live-уроки",
    from: "От",
    to: "До",
    inCalendar: "В календаре",
    registration: "Регистрация",
    I_agree_with: "Я согласен с",
    terms: "Правилами",
    and: "и",
    policies: "Политикой",
    register: "Регистрация",
    forget_password: "Забыли пароль",
    or_login: "или Войти",
    reset_password: "Сброс пароля",
    select_student: 'Выберите студента'
  },
  calendar: {
    January: "Январь",
    February: "Февраль",
    March: "Март",
    April: "Апрель",
    May: "Май",
    June: "Июнь",
    July: "Июль",
    August: "Август",
    September: "Сентябрь",
    October: "Октябрь",
    November: "Ноябрь",
    December: "Декабрь",
    Exams: "Экзамены",
    Lessons: "Уроки",
    Online_Lessons: "Live-уроки",
    Filter_disciplines: "Фильтровать дисциплины",
    Year: "Год",
    Month: "Месяц",
    Week: "Неделя",
    МО: "Пн",
    TU: "Вт",
    WE: "Ср",
    TH: "Чт",
    FR: "Пт",
    SA: "Сб",
    SU: "Вс",

    МОN: "Пон",
    TUE: "Вто",
    WED: "Сре",
    THU: "Чет",
    FRI: "Пят",
    SAT: "Суб",
    SUN: "Вос"
  },
  msgs: {
    login_success: 'На СтарТ, ВниманиЕ, Марш к Знаниям! :-)',
    success: 'Ура, Мы сделали это',
    sure_delete: 'Вы уверены, что хотите удалить?',
    sure_delete_lesson: 'Вы уверены, вы хотите удалить урок?',
    delete_subject_only: 'Удалить только предмет',
    delete_subject_with_lessons: 'Удалить предмет вместе с уроками'
  },
  forms: {
    set_new_password: "Установить новый пароль",
    current_password: "Текущий пароль",
    new_password: "Новый пароль",
    re_new_password: "Повторите новый пароль",
    click_to_set_photo: "Нажмите, чтобы установить фотографию",
    write_password: "Введіть пароль",
    re_password: 'Повторіть пароль',
    set_your_account_password: 'Встановити пароль до свого облікового запису',
    set_password: 'Встановити пароль'
  }
};
