import { CLASSSHEDULE_ADD } from "../actions/type";

const initialState = {
  data: [
    {
      tableContent: [
        {
          date: '',
          taskNames: []
        }
      ]
    }
  ],
  isInitial: true
}

export default (state = initialState, action) => {
  switch (action.type) {
    // case SET_CLASS_SHEDULE:
    //   return action.payload;

    case CLASSSHEDULE_ADD:
      if (state.isInitial) {
        return {
          ...state,
          data: [action.payload],
          isInitial: false
        }
      } else {
        if (state.data.some(s => s._id === action.payload._id)) return state
        const data = [...state.data]
        data.push(action.payload)
        return {
          ...state,
          data: data
        }
      }

    default:
      return state;
  }
};