export default {
  common: {
    users: 'Користувач',
    add: 'Додати',
    update: 'Оновити',
    show_result: 'Показати результат',
    save: "Зберегти",
    save_and_go_back: 'Зберегти і повернутися назад',
    upload: 'Завантажити',
    uploaded: 'Завантажено',
    no_file_choosen: 'Файл не обрано',
    video: 'Відео',
    photo: 'Фотографія'
  },
  toolbar: {
    header: 'Заголовок',
    paragraph: 'Параграф',
    question_answer: 'Питання з правильною відповіддю',
    question_answers: 'Питання з правильними відповідями',
    question_answer_photo: 'Питання з фото',
    answer_choice: 'Варіант відповіді',
    find_compliance: 'Підбір відповідності',
    question: 'Питання',
    toolbox: 'Ящик для інструментів',
    score_for: 'Оцінка за правильну відповідь',
    correct_answer: 'Правильна відповідь',
    release_to_add: 'Відпустіть, щоб додати',
    drag_here: 'Перетягніть коробку сюди',
    have_unsaved: 'У вас є незбережені зміни, ви впевнені, що хочете піти?',
    create_by_yourself: 'Створити самому',
    task_with_text_response: 'Завдання з текстовою відповіддю',
    task_description: 'Опис завдання',
    write_answer_here: 'Напишіть відповідь тут',
    columns: 'Стовпці',
    rows: 'Рядок',
    placeholder: 'Заповнювач',
    upload_file: 'Завантажити файл',
    download_answer: 'Завантажити відповідь'
  },
  header: {
    logo: require("../../assets/logo/SVG/logo(ua).svg"),
    profile: "Профіль",
    logout: "Вийти"
  },
  banner: {
    USDE: "FOCUS",
    Biggest_online_school: "Найбільша онлайн школа для дистанційної освіти.",
    REGISTER_NOW: "ЗАРЕЄСТРУВАТИСЯ",
    logo: require("../../assets/logo/SVG/logo-full(ua).svg")
  },
  discipline: {
    more_than: "Більше 100 дисциплін",
    our_service:
      "Наш сервіс надає розширену шкільну програму для всіх класів і має багато додаткових матеріалів."
  },
  labels: {
    start: 'СТАРТ',
    authorization: 'Авторизація',
    admin: "Адміністратор",
    admins: "Адміністратор",
    teacher: "Вчитель",
    teachers: "Вчителі",
    parent: "Батько",
    parents: "Батьки",
    students: "Учні",
    student: 'Учень',
    createAdmin: "Створити адміністратора",
    editAdmin: "Редагувати адміністратора",
    newteachers: "Нові вчителі",
    createteacher: "Створити вчителя",
    editteacher: "Редагувати вчителя",
    createparent: "Створити батьків",
    editparent: "Редагувати батьків",
    createstudent: "Створити учня",
    editstudent: "Редагувати учня",
    approve: "ЗАТВЕРДИТИ",
    decline: "ВІДМОВИТИ",
    chat: "Чат",
    searchuser: "Пошук користувача",
    searchconversation: "Знайти бесіду",
    email: "Email",
    password: "Пароль",
    save: "Зберегти",
    hello: "Вітаємо",
    opencalendar: "КАЛЕНДАР",
    progress: "Прогрес",
    todolist: "Список справ",
    todo: "Зробити",
    login: "Вхід",
    forgotpassword: "Забули пароль?",
    not_registered_yet: 'Ще не зареєстровані?',
    completed: "Завершено",
    tasks: "Справи",
    enterNameHere: "Введіть назву тут",
    enterhere: "Введіть тут...",
    firstname: "Ім'я",
    lastname: "Прізвище",
    phone: "Телефон",
    description: "Опис",
    disciplines: "Дисципліни",
    profile: "Профіль",
    avatar: "Аватар",
    username: "Ім'я користувача",
    examplelogin: "example_login",
    createcontent: "Створення контенту",
    createprogram: "Створення програми",
    createlesson: "Створення Уроку",
    title: "Назва",
    withhometask: "З домашнім завданням",
    exam: "Іспит",
    button: "КНОПКА",
    selectyourdisciplines: "Введіть дисципліну тут...",
    content: "Вміст",
    name: "Ім'я",
    date: "Дата",
    search: "Пошук",
    filters: "Фільтри",
    public: "ПУБЛІЧНИЙ",
    hidden: "ПРИХОВАНИЙ",
    archived: "АРХІВОВАНИЙ",
    enterDisciplineHere: "Введіть дисципліну тут...",
    enterTeacherHere: "Введіть вчителя тут...",
    enterStatusHere: "Введіть статус тут...",
    enterTagHere: "Введіть тег тут...",
    program: "Програма",
    language: "???",
    programs: "Програми",
    lessons: "Уроки",
    task: "Завдання",
    livelessons: "Live-уроки",
    from: "З",
    to: "До",
    inCalendar: "У календарі",
    registration: "Реєстрація",
    I_agree_with: "Я погоджуюся з",
    terms: "Умовами",
    and: "та",
    policies: "Політикою",
    register: "Реєстрація",
    forget_password: "Забули пароль",
    or_login: "або Логін",
    reset_password: "Скинути пароль",
    select_student: 'Виберіть студента'
  },
  calendar: {
    January: "Січень",
    February: "Лютий",
    March: "Березень",
    April: "Квітень",
    May: "Травень",
    June: "Червень",
    July: "Липень",
    August: "Серпень",
    September: "Вересень",
    October: "Жовтень",
    November: "Листопад",
    December: "Грудень",
    Exams: "Іспити",
    Lessons: "Уроки",
    Online_Lessons: "Live-уроки",
    Filter_disciplines: "Фільтрувати дисципліни",
    Year: "Рік",
    Month: "Місяць",
    Week: "Тиждень",
    МО: "Пн",
    TU: "Вт",
    WE: "Ср",
    TH: "Чт",
    FR: "Пт",
    SA: "Сб",
    SU: "Нд",

    МОN: "Пон",
    TUE: "Вів",
    WED: "Сер",
    THU: "Чтв",
    FRI: "Птн",
    SAT: "Суб",
    SUN: "Нед"
  },
  msgs: {
    login_success: 'На Старт, Увага, ВПЕРЕД до Знань! :-)',
    success: 'Супер, все готово',
    sure_delete: 'Ви впевнені, що хочете видалити?',
    sure_delete_lesson: 'Ви впевнені, ви хочете видалити урок?',
    delete_subject_only: 'Видалити тільки предмет',
    delete_subject_with_lessons: 'Видалити предмет разом з уроками'
  },
  forms: {
    set_new_password: "Відправити новий пароль",
    current_password: "Поточний пароль",
    new_password: "Новий пароль",
    re_new_password: "Повторіть новий пароль",
    click_to_set_photo: "Натисніть, щоб встановити фотографію",
    write_password: "Введіть пароль",
    re_password: 'Повторіть пароль',
    set_your_account_password: 'Встановити пароль до свого облікового запису',
    set_password: 'Встановити пароль'
  }
};
