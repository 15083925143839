import React, { Component, Suspense, lazy } from "react";
import { connect } from 'react-redux'
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from 'react-toastify'
import ReactTooltip from 'react-tooltip'

import { checkUserAction } from './actions/userActions'
import { setLanguage } from './actions'
import MainHeader from "./components/MainHeader";
import SiteFooter from "./components/SiteFooter";
import { getLanguage } from "./services/language";

import "./App.scss";
import 'react-toastify/dist/ReactToastify.css'
import './styles/Toastify.scss'

const Registration = lazy(() => import('./containers/Registration'))
const Login = lazy(() => import('./containers/Login'))
const Home = lazy(() => import('./containers/Home'))
const ForgetPassword = lazy(() => import('./containers/ForgetPassword'))
const Terms = lazy(() => import('./containers/Terms'))
const Privacy = lazy(() => import('./containers/Privacy'))
const SetPassword = lazy(() => import('./containers/SetPassword'))
const Page404 = lazy(() => import('./containers/Page404'))
const DashboardMainScreen = lazy(() => import('./containers/DashboardMainScreen'))

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userStatus: false
    };
  }

  componentDidMount() {
    this.props.checkUserAction()
    this.props.hideLoading()
    if (!this.props.lang) this.props.setLanguage(getLanguage())
  }

  render() {

    const { modes } = this.props;
    return (
      <div className="App">
        <BrowserRouter>
          <div>
            <MainHeader />
            <Suspense fallback={<div style={{height: '100vh'}}>Loading...</div>}>
              <Switch>
                <Route
                  path="/"
                  exact
                  component={Home}
                />
                <Route
                  path="/registration/"
                  component={Registration}
                />
                <Route
                  path="/login/"
                  component={Login}
                />
                <Route
                  path="/setpassword/:token"
                  component={SetPassword}
                />
                <Route
                  path="/forget-password/"
                  component={ForgetPassword}
                />
                <Route
                  path="/dashboard/"
                  component={DashboardMainScreen}
                />
                <Route
                  path="/terms"
                  exact
                  component={Terms}
                />
                <Route
                  path="/privacy"
                  exact
                  component={Privacy}
                />

                <Route component={Page404} />
              </Switch>
            </Suspense>
            {!modes.isDashboard && <SiteFooter />}
            <ToastContainer 
              autoClose={5000}
              hideProgressBar
              
            />
            <ReactTooltip />
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  modes: state.modes,
  lang: state.lang.lang
})

export default connect(mapStateToProps, { checkUserAction, setLanguage }) (App)
